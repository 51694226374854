import {
  GLOBAL,
  GlobalAction,
  TimelineAction,
  TIMELINE,
  SEGMENT,
  SegmentAction,
  PlayerAction,
  DeviceAction,
  DEVICE,
  PLAYER,
  ShowSubtitlesPayload
} from './actions';
import {
  Mode,
  PlayerState,
  Root,
  RootState,
  Segment,
  TimelineState,
  ViewType,
  CurrentTime,
  QualityLevel,
  RewindingState,
  PlayerProgress,
  PopupProps,
  Language,
  ExtendedVideoJsPlayer
} from '../types';

export const fullscreen = (payload: boolean): PlayerAction => ({
  type: PLAYER.FULLSCREEN,
  payload
} as const);

export const firstInteraction = (payload: number): GlobalAction => ({
  type: GLOBAL.FIRST_INTERACTION,
  payload
} as const);

export const setVolume = (payload: number): PlayerAction => ({
  type: PLAYER.SET_VOLUME,
  payload
} as const);

export const setTransitionPlayer = (payload: PlayerState): GlobalAction => ({
  type: GLOBAL.SET_TRANSITION_PLAYER,
  payload
} as const);

export const slowInternetConnection = (payload: boolean): GlobalAction => ({
  type: GLOBAL.SLOW_INTERNET_CONNECTION,
  payload
} as const);

export const showSubtitles = (payload: ShowSubtitlesPayload): GlobalAction => ({
  type: GLOBAL.SHOW_SUBTITLES,
  payload
} as const);

export const setLanguage = (payload: Language): GlobalAction => ({
  type: GLOBAL.SET_LANGUAGE,
  payload
} as const);

export const isMobile = (payload: boolean): DeviceAction => ({
  type: DEVICE.IS_MOBILE,
  payload
} as const);

export const setDevice = (payload: string): DeviceAction => ({
  type: DEVICE.SET_DEVICE,
  payload
} as const);

export const setOs = (payload: string): DeviceAction => ({
  type: DEVICE.SET_OS,
  payload
} as const);

export const setBrowser = (payload: string): DeviceAction => ({
  type: DEVICE.SET_BROWSER,
  payload
} as const);

export const showControlBar = (payload: { top: boolean | 'any' } | { bottom: boolean | 'any' }): PlayerAction => ({
  type: PLAYER.SHOW_CONTROLBAR,
  payload,
} as const);

export const showPopup = (payload: PopupProps | undefined): GlobalAction => ({
  type: GLOBAL.SHOW_POPUP,
  payload
} as const);

export const setShowBg = (payload: boolean): GlobalAction => ({
  type: GLOBAL.SET_SHOW_BG,
  payload
} as const);

export const setDisabledButtons = (payload: { prev_btn: boolean } | { next_btn: boolean }): PlayerAction => ({
  type: PLAYER.SET_DISABLED_BUTTONS,
  payload
} as const);

export const setFoundSegment = (payload: Segment): GlobalAction => ({
  type: GLOBAL.SET_FOUND_SEGMENT,
  payload
} as const);

export const updateProgress = (payload: PlayerProgress[]): PlayerAction => ({
  type: PLAYER.UPDATE_PROGRESS,
  payload
} as const);

export const addPlayerProgress = (payload: PlayerProgress): PlayerAction => ({
  type: PLAYER.SET_PROGRESS,
  payload,
} as const);

export const setChooseMoodEnabled = (payload: boolean): SegmentAction => ({
  type: SEGMENT.SET_CHOOSE_MOOD_ENABLED,
  payload
} as const);

export const setPaused = (payload: boolean): PlayerAction => ({
  type: PLAYER.SET_PAUSED,
  payload
} as const);

export const setSeekedTime = (payload: number | undefined): TimelineAction => ({
  type: TIMELINE.SET_SEKKED_TIME,
  payload,
  rate_limit: 100
} as const)

export const setRewinding = (payload: RewindingState): GlobalAction => ({
  type: GLOBAL.SET_REWINDING,
  payload,
} as const);

export const setReservedPlayerState = (payload: PlayerState): GlobalAction => ({
  type: GLOBAL.SET_RESERVED_PLAYER_STATE,
  payload
} as const);

export const setVoiceRecognitionEnabled = (payload: boolean): SegmentAction => ({
  type: SEGMENT.SET_VOICE_RECOGNITION_ENABLED,
  payload
} as const);

export const setQualityLevel = (payload: QualityLevel): GlobalAction => ({
  type: GLOBAL.SET_QUALITY_LEVEL,
  payload
} as const);

export const setDuration = (payload: number): TimelineAction => ({
  type: TIMELINE.SET_DURATION,
  payload
} as const);

export const setTimeline = (payload: TimelineState): TimelineAction => ({
  type: TIMELINE.SET_TIMELINE,
  payload
} as const);

export const setCurrentTime = (payload: CurrentTime): TimelineAction => ({
  type: TIMELINE.SET_CURRENT_TIME,
  payload,
  rate_limit: 300
} as const);

export const registerPlayer = (payload: ExtendedVideoJsPlayer): GlobalAction => ({
  type: GLOBAL.REGISTER_PLAYER,
  payload
} as const);

export const unregisterPlayer = (payload: string): GlobalAction => ({
  type: GLOBAL.UNREGISTER_PLAYER,
  payload
} as const);

export const setScenario = (payload: Segment[]): GlobalAction => ({
  type: GLOBAL.SET_SCNERATIO,
  payload
} as const);

export const setActivePlayer = (payload: string): GlobalAction => ({
  type: GLOBAL.CHANGE_ACTIVE_PLAYER,
  payload,
  rate_limit: 500
} as const);

export const setSegmentForActivePlayer = (payload: string): GlobalAction => ({
  type: GLOBAL.SET_SEGMENT_FOR_ACTIVE_PLAYER,
  payload
} as const);

export const nextSegment = (payload: string): GlobalAction => ({
  type: GLOBAL.NEXT_SEGMENT,
  payload,
  rate_limit: 300
} as const);

export const prevSegment = (payload: string): GlobalAction => ({
  type: GLOBAL.PREV_SEGMENT,
  payload
} as const);

export const updateState = (payload: Root): GlobalAction => ({
  type: GLOBAL.UPDATE_STATE,
  payload
} as const);

export const setMode = (payload: Mode): GlobalAction => ({
  type: GLOBAL.SET_MODE,
  payload
} as const);

export const setViewType = (payload: ViewType): GlobalAction => ({
  type: GLOBAL.SET_VIEW_TYPE,
  payload
} as const);