import Controlbar from './cb';

export type Menus = 'quality_levels' | 'language' | 'subtitles' | 'mobile' | 'x' | 'choose_mode';

const CB = ({ ...props }) => {
  return (
    
        <Controlbar id = { 'cb_' }
        { ...props }
        />
  )
}

export default CB;