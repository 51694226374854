import store from '../state/store';
import { ExtendedVideoJsPlayer } from '../types';
import videojs, { VideoJsPlayer } from 'video.js';
import {
  Root
} from '../types';

export type Player_label = 'active' | 'inactive' | 'sub';
export type Event = 'timeupdate' | 'progress';
export type EventCallback = () => void;
export interface VJSPlayerWithLabel {
  player: VideoJsPlayer
  label: Player_label,
  source?: string
}

const CONTROLBAR_ID = 'cb-id';

class Player {
  closeFS() {
    const players = this.getPlayers();
    for (const player_container of players) {
      const player = player_container.player;
      if (player.isFullscreen()) {
        player.exitFullscreen();
        continue;
      }
    }
  }

  isAnyPlayerInFullscreen() {
    const players = this.getPlayers();
    for (const player_container of players) {
      const player = player_container.player;
      if (player.isFullscreen()) {
        return true;
      }
    }
    return false;
  }

  findExistedPlayer(source: string): VideoJsPlayer | undefined {
    const players = this.getPlayers();
    for (const player of players) {
      //console.log('SOURCE COMPARE', player.player.src(), source)
      if (player.player.src() === source) {
        return player.player;
      }
    }
  }

  distinguishPlayerBySource(source: string, state?: Root): Player_label {
    if (typeof source !== 'string' || (typeof source === 'string' && source.length < 10)) {
      return 'inactive';
    }

    let _state = state ? state : store.getState().root;

    const splitted = source.split('/').reverse();

    const parts_indexes = {
      language: 1,
      segment_name: 3
    };

    const state_values = {
      segment_name: _state.active_player_id,
      language: _state.language
    };

    const source_values = {
      segment_name: splitted[parts_indexes['segment_name']],
      language: splitted[parts_indexes['language']]
    };
    //console.log('source_values', source_values);
    //console.log('state_values', state_values);
    if (state_values.language === source_values.language) {
      return 'active';
    }

    return 'sub';
  }

  isFullScreen() {
    const _player = this.getPlayer();
    if (!_player) {
      return false;
    }
    return _player.player_.isFullscreen();
  }

  fullscreen(cancel?: boolean) {
    const _player = this.getPlayer();
    if (!_player) {
      return;
    }
    if (cancel === false) {
      _player.player_.exitFullscreen();
      return;
    }
    _player.player_.requestFullscreen();
  }

  volume(_volume: number) {
    if (_volume < 0 || _volume > 100) {
      return;
    }
    let volume = parseFloat((_volume / 100).toString().slice(0, 3));
    const players = this.getPlayers();
    for (const p of players) {
      const player = p.player;
      if (typeof player.volume === 'function') {
        player.volume(volume);
      }
    }
  }

  playing(): boolean {
    //videojs('gg').hasStarted;
    const _player = this.getPlayer();
    //console.log('playing ', _player.id(), _player.hasStarted(), _player.paused());
    if (!_player) {
      return;
    }

    if (typeof _player.hasStarted === 'function' && !_player.hasStarted()) {
      return false;
    }

    return typeof _player.pause === 'function' ? !_player.paused() : false;
  }

  play() {
    let some_already_started = false;
    const players = this.getPlayers();
    for (const player_container of players) {
      const player = player_container.player;
      const playing = typeof player.paused === 'function' && !player.paused();
      if (player_container.label !== 'active') {
        if (playing) {
          player.pause();
          continue;
        }
      }
      if (player_container.label === 'active' && !some_already_started) {
        const element = player.el();
        //@ts-ignore
        const player_index = element.style.zIndex;
        //@ts-ignore
        const window_index = window.active_player_z_index;
        player.play();

        if (player_index < window_index) {
          //@ts-ignore
          //element.style.zIndex = window_index + 1;
        }

        some_already_started = true;
      }
    }
  }

  pause() {
    const players = this.getPlayers();
    for (const player_container of players) {
      const player = player_container.player;
      if (!player.paused()) {
        player.pause();
      }
    }
  }

  duration() {
    const { player_ : player } = this.getPlayer();
    return player.duration();
  }

  currentTime(time?: number): number {
    const { player_ : player } = this.getPlayer();
    if (typeof time === 'number') {
      player.currentTime(time);
      return time;
    }
    return player.currentTime();
  }

  bufferedEnd() {
    const { player_ : player } = this.getPlayer();
    return player.bufferedEnd();
  }

  on(event: Event, callback: EventCallback) {
    const { player_ : player } = this.getPlayer();
    return player.on(event, callback);
  }

  off(event: Event, callback: EventCallback) {
    const { player_ : player } = this.getPlayer();
    return player.off(event, callback);
  }

  getAttribute(attr: string) {
    const { player_ : player } = this.getPlayer();
    return player.getAttribute(attr);
  }

  addClass(className: string) {
    const cb = this.getControlbar();
    return cb.classList.add(className);
  }
  
  removeClass(className: string) {
    const cb = this.getControlbar();
    return cb.classList.remove(className);
  }

  getPlayers(): VJSPlayerWithLabel[] {
    const players = videojs.getAllPlayers();
    const active_players: VJSPlayerWithLabel[] = [];
    const players_with_labels: VJSPlayerWithLabel[] = [];
    for (const player of players) {
      const label = this.distinguishPlayerBySource(player.src());
      players_with_labels.push({
        player,
        label,
        source: player.src()
      });
    }
    for (const p of players_with_labels) {
      if (p.label === 'active') {
        active_players.push(p);

      }
    }

    if (active_players.length > 1) {
      const with_removed_dups = [];
      let biggest_index = {
        id: undefined,
        index: 0
      };
      for (const p of active_players) {
        const el = p.player.contentEl();
        //@ts-ignore
        const z_index = el.style.zIndex;
        if (
          z_index > biggest_index.index
        ) {
          biggest_index = {
            id: p.player.id(),
            index: z_index
          };
          continue;
        }
      }
      const active = players_with_labels.filter(p => p.label !== 'active' || p.player.id() === biggest_index.id);
      /*
      for (const p of active_players) {
        if (p.player.id() !== active[0].player.id()) {
          console.log("DISPOSE NAHUI");
          p.player.src('x');
        }
      }
      */
      return active;
    }
    return players_with_labels;
  }

  getPlayer(id?: string): VideoJsPlayer | any {
    const players = this.getPlayers();
    for (const player_container of players) {
      const player = player_container.player;
      if (player_container.label === 'active') {
        return player;
      }
    }
    return {};
  }

  getVJSPlayer(id: string): ExtendedVideoJsPlayer | null {
    let player;
    try {
      player = videojs(id);
    }
    catch(error) {
      this.handleError(error);
      return null;
    }
    if (!player) {
      return null;
    }
    return player;
  }

  getActivePlayerId() {
    return store.getState().root.active_player_id;
  }

  getControlbar() {
    return document.getElementById(CONTROLBAR_ID);
  }

  handleError(error: string) {
    console.log(error);
  }

  do(method: keyof Player, args: any[]) {
    const stop_marks = ['do', 'controlbar', 'addClass', 'removeClass'];
    if (stop_marks.includes(method)) {
      return;
    }

    //@ts-ignore
    return this[method](...args);
  }

  async timeout(ms: number) {
    return new Promise((res, rej) => {
      const timeout = setTimeout(() => {
        res(true);
        clearTimeout(timeout);
      }, ms);
    })
  }

  controlBar: {}
}

const Instance = new Player();

Instance.controlBar = {
  addClass: (className: string) => {
    return Instance.addClass(className);
  },
  removeClass: (className: string) => {
    return Instance.removeClass(className);
  },
  someMenuOpen: (): boolean => {
    
    return false;
  }
}

export { Instance as Player };