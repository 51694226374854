import {
  RootState,
  VideoOptions,
  Segment,
  PlayerLabel
} from '../types';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import Player from './player';
import React, { useEffect, useRef, useState } from 'react';
import {
  setScenario,
  setSegmentForActivePlayer,
  setMode,
  showControlBar,
  setOs,
  setBrowser,
  setDevice,
  setLanguage,
  isMobile,
  slowInternetConnection,
  setVolume,
  firstInteraction
} from '../state/action-creators';
import Popup from './popup';
import DeviceDetector from 'device-detector-js';
import NotSupportedMessage from './not-supported-message';
import selectSource from '../utils/select-source';
import Controlbar from './controlbar';
import ControlbarTop from './controlbar-top';
import LoadingSpinner from './loading-spinner';
import Cookies from 'universal-cookie';
import aws_cookies from '../aws-cookies';
import KeyboardHandler from './keyboard-handler';
import useWindowSize from './use-window-resize';

const deviceDetector = new DeviceDetector();
const cookies = new Cookies();

for (const cookie of aws_cookies) {
  const key = Object.keys(cookie)[0];
  cookies.set(key, cookie[key], { domain: 'cdn-deep-movie.site' });
}

let _first_interaction = false;

function View({
  initial_language,
  options,
  config,
  scenario,
}) {
  const scenario_ids = scenario.map(item => item.id);
  let [box_height, setBoxHeight] = useState(0);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  });
  const dispatch = useAppDispatch();
  const {
    mode,
    popup,
    quality_level,
    show_bg
  } = useAppSelector(state => state.root);

  const info = deviceDetector.parse(window.navigator.userAgent);
  const device = info.device;
  const isSafari = info.client.name.toLowerCase() === 'safari' ||
    info.client.name.toLowerCase().includes('safari');
  const type = info.device.type.slice();
  const isIos = info && info.os.name.toLowerCase() === 'ios';

  const { width, height } = useWindowSize();

  //const isIos = true;
  useEffect(() => {
    if (isSafari) {
      //console.log('<--- SAFARI DETECTED. APPLYING REQUIRED CONFIGS...');
    }
    // Set scenario of the film in the beggining
    if (Array.isArray(scenario)) {
      dispatch(setScenario(scenario.map((segment, index) => {
        segment.duration *= 1000;
        if (segment.id.includes('NI')) {
          const new_src = selectSource(initial_language, isSafari, quality_level, false, isIos);
          segment.src = new_src;
        }
        return segment;
      })));
      //@ts-ignore
      dispatch(setSegmentForActivePlayer('S_NI_en'));
    }
    setBoxHeight(document.getElementById('box').offsetHeight);
    setTimeout(() => {
      dispatch(setMode('normal'));
    }, 1000);
    dispatch(setOs(info.os.name));
    dispatch(setBrowser(info.client.name.toLowerCase()));
    dispatch(setDevice(info.device.type.toLowerCase()));
    if (
      isMobileDevice()
      ) {
      dispatch(isMobile(true))

      dispatch(showControlBar({
        top: true,
        bottom: true
      }));
    }
    else {
      dispatch(isMobile(false));
    }

    //@ts-ignore
    if (window.slow_internet_connection) {
      dispatch(slowInternetConnection(true));
    }

    document.addEventListener('click', detectFirstInteraction, true);
  }, []);

  function detectFirstInteraction() {
    dispatch(firstInteraction(Date.now()));
    _first_interaction = true;
    document.removeEventListener('click', detectFirstInteraction, true);
  }

  const isMobileDevice = () => type.toLowerCase() === 'smartphone' ||
  type.toLowerCase().includes('smartphone') ||
  type.toLowerCase().includes('mobile') ||
  type.toLowerCase().includes('phone') ||
  info.os.name.toLowerCase().includes('ios') ||
  info.os.name.toLowerCase().includes('android')

  const players: PlayerLabel[] = [
    'normal',
    'reserved',
    'transition'
  ];

  const isBrowserSupported = (client) => {
    const supported_browsers = ['safari', 'chrome', 'firefox', 'yandex', 'opera'];
    if (supported_browsers.some(name => client.includes(name))) {
      return true;
    }
    return false;
  }

  const choose_mode_popup = {
    title: "Which way you'd like to watch the movie?",
    actions: [
      {
        label: 'Normal',
        cb: () => dispatch(setMode('normal'))
      },
      {
        label: 'Immersive',
        cb: () => dispatch(setMode('immersive')),
        disabled: true
      }
    ]
  };

  const onMouseMove = (e) => {
    const y = e.clientY;
    //console.log('screenX', e.screenX, e)
    const top_controlbar_range_percent = 30;
    const bottom_controlbar_range_percent = 20;
    let is_in_range_of_something = false;

    if (!_first_interaction) {
      dispatch(showControlBar({
        bottom: true,
        top: true
      }));
      return;
    }
    //@ts-ignore
    const height = dimensions.width / 2;
    const top = {
      range: [0, ((height / 100) * top_controlbar_range_percent)],
      name: 'top'
    };
    const bottom = {
      range: [height - ((height / 100) * bottom_controlbar_range_percent), height],
      name: 'bottom'
    };

    const target = e.target;
    const zindex = target.style.zIndex;
    if (zindex) {
      console.log('not dispatching');
      return;
    }
    const className = target.className;
    if (!className.includes('dont-highlight')) {
      return;
    }
    //console.log('tarrget', target);
    //console.log('zinde', zindex);

    [top, bottom].forEach(controlbar => {
      console.log('con', controlbar.range[0], controlbar.range[1], y)
      const last_range = isSafari ? controlbar.range[1] + 100 : controlbar.range[1];
      //console.log(`last_range: ${last_range} | y: ${y} | height: ${height}`);
      if (controlbar.name === 'bottom') {
        if (y >= controlbar.range[0]) {
          is_in_range_of_something = true;
          console.log('dispatching bottom true')
          dispatch(showControlBar({
            'bottom': true
          }));
          return;
        }
      }
      if (y >= controlbar.range[0] && y <= last_range) {
        is_in_range_of_something = true;
                  //@ts-ignore
        dispatch(showControlBar({
          [controlbar.name]: true
        }));
      }
    });
    if (!is_in_range_of_something) {
      dispatch(showControlBar({
        bottom: false,
        top: false
      }));
    }
  }

  const renderControls = () => {
    const controls = [Controlbar, ControlbarTop, LoadingSpinner];
    return controls.map((C, i) => {
      
      return <C id = { 'any' } key = { `control_${i}` }  />;
    });
  }

  const _onMobileClick = (e: React.MouseEvent) => {
    const cont = view_ref.current;
    const y = e.clientY - cont.offsetTop;
    const one = box_height / 100;
    const percents = y / one;
    if (percents < 30) {
      dispatch(showControlBar({
        top: true
      }));
      return;
    }
    if (percents > 70) {
      dispatch(showControlBar({
        bottom: true
      }));
      return;
    }
    dispatch(showControlBar({
      top: false,
      bottom: false
    }));
    return;

  }

  function hideControlbars(player_height: number, click_position: number) {
    const percent = 30;
    const c = click_position / (player_height / 100);
    console.log('%', c);
  }

  const is_mobile = isMobileDevice();
  const view_ref = useRef<HTMLDivElement>(null);

  const onBodyMouseMove = (e) => {
    const y = e.screenY;
    if (y <= box_height) {
      return;
    }
    dispatch(showControlBar({
      bottom: false
    }));
  }

  useEffect(() => {
    const box = view_ref.current;
    if (box) {
      const parent = box.parentNode;
      //@ts-ignore
      const p_w = parent.offsetWidth;
      //@ts-ignore
      const p_h = parent.offsetHeight;

      const d = matchAspectRatio({ width: p_w, height: p_h }, [16, 9]);

      setDimensions({
        ...d
      });
    }
  }, [view_ref.current, width, height]);

  function matchAspectRatio(dimensions: { width: number, height: number }, ratio: number[]): { width: number, height: number } {
    const viewport_height = height;
    const perfect_height = width / ratio[0] * ratio[1];

    if (viewport_height > perfect_height) {
      return {
        height: perfect_height,
        width: perfect_height / ratio[1] * ratio[0]
      };
    }
    return {
      width: viewport_height / ratio[1] * ratio[0],
      height: viewport_height
    };
  }

  return (
    <div
    //onMouseMove = { onBodyMouseMove }
    className = 'u-body'
    style = {{
      width: '100%'
    }}
    >
    <div
    //onClick = { onMobileClick }
    ref = { view_ref }
    id = 'box'
    className = 'box'
    style = {{
      maxHeight: '100vh',
      ...dimensions
    }}
    >
      <div className = { 'players_container' }>
      {
        players.map(label => (
            <Player
            //@ts-ignore
            mode = { config.mode }
            src = { config.src }
            //@ts-ignore
            isMobile = { is_mobile }
            isSafari = { isSafari }
            //@ts-ignore
            device = { device }
            key = { label }
            config = { config }
            label = { label }
            isIos = { isIos }
            />
        ))
      }
      </div>
      {
        mode === 'initial' && false &&
        <Popup
        title = { choose_mode_popup.title }
        actions = { choose_mode_popup.actions }
        />
      }
      {
        //show_bg
        false &&
        <div className = 'video-bg'></div>
      }
      {
        popup &&
        <Popup
        title = { popup.title }
        actions = { popup.actions }
        />
      }
      {
        !isBrowserSupported(info.client.name.toLowerCase()) &&
        <NotSupportedMessage/>
      }
      {
        renderControls()
      }
      <KeyboardHandler/>
    </div>
    </div>
  );
}

export default View;