import { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import ProgressControl from '../progress-control';
import LangBtn from '../buttons/language';
import SubsBtn from '../buttons/subtitles';
import QualityLevelsMenu from '../buttons/quality-levels';
import VolumeBtn from '../buttons/volume';
import PlayButton from '../buttons/play';
import FullscreenBtn from '../buttons/fullscreen';
import {
  RootState
} from '../../types';
import {
  setCurrentTime,
  setTimeline,
  setViewType,
  setRewinding,
  setQualityLevel,
  setLanguage,
  showSubtitles,
  setVolume,
  showControlBar
} from '../../state/action-creators';
import styles from './cb.module.scss';
import MobileMenu from '../mobile-menu';
import { useIdleTimer } from 'react-idle-timer';
import { Player } from '../../player';

export type Menus = 'quality_levels' | 'language' | 'subtitles' | 'mobile' | 'x' | 'choose_mode';
const CONTROLBAR_ID = 'cb-id';

const cb_hide_timeout = 3000;

const never = 'never';

const Controlbar = ({
  setCurrentTime,
  setTimeline,
  timeline,
  view_type,
  setViewType,
  language,
  quality_level,
  playing,
  show_bottom_controlbar,
  setQualityLevel,
  active_player_id,
  rewinding,
  isMobile,
  setLanguage,
  subtitles,
  showControlBar,
  volume,
  setVolume,
  showSubtitles,
  os,
  vjs_player,
  first_interaction,
}: any) => {
  
  const c = 'u-on-bottom';
  const c_t = 'u-on-top';
  const isIos = typeof os === 'string' && os.toLowerCase().includes('ios');
  const [openedMenu, setOpenedMenu] = useState<Menus>();

  const menu_ref = useRef(openedMenu);
  menu_ref.current = openedMenu;

  function timeout(s: number) {
    return new Promise((res, rej) => {
      const timeout = setTimeout(() => {
        res(true);
        clearTimeout(timeout);
      }, s);
    })
  }

  const m_style = {};

  if (isMobile) {
    //@ts-ignore
    m_style.display = 'none';
  }

  useEffect(() => {
    const bar = cb_ref_content.current;
    if (!bar) {
      console.log('!bar')
      return;
    }

    if (show_bottom_controlbar) {
      console.log('show_bottom_controlbar')
      _showBar();
      return;
    }
    hideBar();
  }, [show_bottom_controlbar]);

  const cb_ref_content = useRef<HTMLDivElement>(null);

  function _showBar() {
    const bar = cb_ref_content.current;
    if (!bar) {
      return;
    }
    bar.classList.add(c_t);
    bar.classList.remove(c);
  }
  async function _hideBar() {
    const bar = cb_ref_content.current;
    if (!bar) {
      return;
    }
    await timeout(cb_hide_timeout);
    const check = typeof menu_ref.current !== 'undefined';
    if (check) {
      return;
    }
    console.log('hiding')
    bar.classList.add(c);
    bar.classList.remove(c_t);
    showControlBar({
      bottom: false
    });
  }

  function hideBar() {
    const bar = cb_ref_content.current;
    if (!bar) {
      return;
    }
    bar.classList.add(c);
    bar.classList.remove(c_t);
  }
/*
  useEffect(() => {
    if (!openedMenu) {
      console.log('openedMenu hide!!!')
      //_hideBar();
    }
  }, [openedMenu]);
  useEffect(() => {
    if (typeof first_interaction === 'number') {
      //_hideBar();
    }
  }, [first_interaction]);

*/
const onIdle = () => {
    if (openedMenu) {
      return;
    }
    if (!first_interaction) {
      return;
    }
    showControlBar({
      bottom: false
    });
  };

  useEffect(() => {
    if (first_interaction) {
      timer.start();
    }
  }, [first_interaction]);

  useEffect(() => {
    if (!openedMenu && show_bottom_controlbar) {
      timer.start();
    }
  }, [openedMenu]);

  const onAction = () => {
    showControlBar({
      bottom: true
    });
  };
  const interaction = (e) => {
    e.preventDefault();
    const _player = Player.getPlayer();
    if (!_player) {
      return;
    }
    const player = _player.player_;
    if (!player) {
      return;
    }

    if (!player.hasStarted()) {
      return player.play();
    }

    if (Player.playing()) {
      player.pause();
      //onMobileClick(e);
      return;
    }
    player.play();
    //onMobileClick(e);
  }

  const el = cb_ref_content.current;

  const timer_events = isMobile ? ['click'] : ['mousemove'];
  
  const timer = useIdleTimer({
    onIdle,
    onAction,
    element: el ? el : document,
    timeout: 3000,
    //@ts-ignore
    events: timer_events,
    //stopOnIdle
  });

  const veil_height = isMobile ? show_bottom_controlbar ? '50px' : '100px' : '150px';

  const another_interaction = (e) => {
    const p = vjs_player;
    //onMobileClick(e);
    if (p) {
      if (!p.hasStarted() || p.paused()) {
        p.play();
        return;
      }
      p.pause();
    }
  }

  if (!active_player_id.length) {
    return <></>;
  }

  return (
    <div
    //onMouseLeave = { hideBar }
    ref = { cb_ref_content }
    id = { CONTROLBAR_ID }
    className = { styles.cb }>
      <div
      onClick = { isMobile ? () => {} : vjs_player ? another_interaction : interaction }
      style = {{
          position: 'absolute',
          width: '100%',
          left: 0,
          bottom: '100%',
          //height: !show_bottom_controlbar ? '400px' : '250px',
          height: veil_height,
          //background: 'red'
        }}></div>
      <div
      
      className = { styles.cb_content }>

        <div className = { styles.cb_item }>
          <PlayButton
          vjs_player = { vjs_player }
          first_interaction = { first_interaction }
          playing = { playing }
          />
        </div>
        <div
        style = {{ ...m_style }}
        className = { styles.cb_item }>
          <VolumeBtn
          vjs_player = { vjs_player }
          volume = { volume }
          setVolume = { setVolume }
          />
        </div>
        <div className = { styles.cb_item }>
          <ProgressControl
          vjs_player = { vjs_player }
          //@ts-ignore
          setOpenedMenu = { setOpenedMenu }
          timeline = { timeline }
          setTimeline = { setTimeline }
          setViewType = { setViewType }
          setCurrentTime = { setCurrentTime }
          active_player_id = { active_player_id }
          view_type = { view_type }
          rewinding = { rewinding }
          setRewinding = { setRewinding }
          language = { language }
          isMobile = { isMobile }
          openedMenu = { openedMenu }
          menu_id = { 'choose_mode' }
          show_bottom_controlbar = { show_bottom_controlbar }
          />
        </div>
        <div className = { styles.cb_item }>
          {
            isMobile &&
            <MobileMenu
            subtitles = { subtitles }
            showSubtitles = { showSubtitles }
            setLanguage = { setLanguage }
            language = { language }
            isIos = { isIos }
            quality_level = { quality_level }
            setQualityLevel = { setQualityLevel }
            active_player_id = { active_player_id }
            openedMenu = { openedMenu }
            setOpenedMenu = { setOpenedMenu }
            show_bottom_controlbar = { show_bottom_controlbar }
            vjs_player = { vjs_player }
            />
          }
          {
            !isMobile &&
            <QualityLevelsMenu
            vjs_player = { vjs_player }
            menu_id =  { 'quality_levels' }
            language = { language }
            isIos = { isIos }
            quality_level = { quality_level }
            setQualityLevel = { setQualityLevel }
            openedMenu = { openedMenu }
            setOpenedMenu = { setOpenedMenu }
            show_bottom_controlbar = { show_bottom_controlbar }
            />
          }
        </div>
        {
          !isMobile &&
          <div className = { styles.cb_item }>
            <LangBtn
            language = { language }
            setLanguage = { setLanguage }
            openedMenu = { openedMenu }
            setOpenedMenu = { setOpenedMenu }
            show_bottom_controlbar = { show_bottom_controlbar }
            menu_id = { 'language' }
            />
          </div>
        }
        {
          !isMobile &&
          <div className = { styles.cb_item }>
            <SubsBtn
            subtitles = { subtitles }
            showSubtitles = { showSubtitles }
            openedMenu = { openedMenu }
            setOpenedMenu = { setOpenedMenu }
            show_bottom_controlbar = { show_bottom_controlbar }
            menu_id = { 'subtitles' }
            />
          </div>
        }
        <div className = { styles.cb_item }>
          <FullscreenBtn
          vjs_player = { vjs_player }
          isIos = { isIos }
          active_player_id = { active_player_id }
          />
        </div>
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  timeline: state.timeline,
  quality_level: state.root.quality_level,
  language: state.root.language,
  view_type: state.root.view_type,
  playing: !state.player.paused,
  active_player_id: state.root.active_player_id,
  rewinding: state.root.rewinding,
  show_controlbar: state.player.show_controlbar,
  isMobile: state.device.isMobile,
  show_bottom_controlbar: state.player.show_controlbar.bottom,
  subtitles: state.root.subtitles,
  volume: state.player.volume,
  fullscreen: state.player.fullscreen,
  os: state.device.os,
  first_interaction: state.root.first_interaction
}), {
  setCurrentTime,
  setTimeline,
  setViewType,
  setRewinding,
  setQualityLevel,
  setLanguage,
  showSubtitles,
  setVolume,
  showControlBar
})(Controlbar);