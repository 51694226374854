import {
  createLogger
} from 'redux-logger';
import PlayerController from '../player/controller';
import {
  AppAction,
  GLOBAL,
  PLAYER,
  TIMELINE,
  DEVICE
} from './actions';

const logger = createLogger(
  {
    diff: false, // (alpha) show diff between states?
    predicate: (getState, action) => Object.values(GLOBAL).includes(action.type)
  }
);

const customMiddleware = (store: any) => (next: any) => (action: AppAction) => {
  const new_action = Object.assign({}, action);
  const new_payload = PlayerController.handleStateUpdates(store, action);
  //@ts-ignore
  if (new_payload && new_payload.abort) {
    return;
  }
  //@ts-ignore
  new_action.payload = new_payload;
  return next(new_action);
};

let stopped = {};

const throttleMiddleware = (store: any) => (next: any) => (action: AppAction) => {
  if (!action) {
    return;
  }
  //@ts-ignore
  if (!action.rate_limit) {
    return next(action);
  }
  if (stopped[action.type]) {
    return;
  }

  const check = abortSpecialActions(action, store);
  if (check) {
    return;
  }
  
  stopped = {
    ...stopped,
    [action.type]: true
  };

  setTimeout(() => {
    stopped = {
      ...stopped,
      [action.type]: false
    }
    //@ts-ignore
  }, action.rate_limit || 300);

  return next(action);
};

function abortSpecialActions(action, store) {
  
  if (action.type === GLOBAL.FIRST_INTERACTION) {
    const first_interaction = store.getState().root.first_interaction;
    if (first_interaction && typeof first_interaction === 'number') {
      return true;
    }
  }

  return false;
}


export {
  logger,
  customMiddleware,
  throttleMiddleware
};