export function getHeaders(cookies: {[key: string]: string}[]) {
    return cookies.map(item => {
        const key = Object.keys(item)[0];
        return {
            name: 'Set-Cookie',
            value: `${[key]}=${item[key]}`
        }
      })
}

export default [
  {'CloudFront-Policy': 'eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uY2RuLWRlZXAtbW92aWUuc2l0ZSIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoZW4iOnsiQVdTOkVwb2NoVGltZSI6MTY1NDMzOTcwMjcyNX19fV19'},
  {'CloudFront-Key-Pair-Id': 'K30OASVYR7MC65'},
  {'CloudFront-Signature': 'fKAqhg5JW5pY8AvvGaPPNxAFW1JRoI7a6YoNcqt~7hOoVSjfGs35bDqR71VqN8BCZ6gNzyaCXityMrOLDxbfGL~lsrTHMHwTp0ahMRBsT6GNlrNJsQAKSiVNiFziph1jbNhZYwtEfPHytOAdD0MZtinPyHIfVBIARacPAEKIkt7FtqtyC9m9tSgGGaPJdssYmORybmRvGOrlCTVAx6I4uyx0Q-OIhJjG5tGC6GEIVSP60DeWXFY7zqybYy98JkA7WCHaCxeO7Ige1GOePJUlouygjyN6mHP2OKwzTmu-s5Qj7B0nmTYpDz98GY79~gRN1Klv4JFGc0XcDmJuH0ueIQ__'}
];