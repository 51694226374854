import { Player } from "../../types";
import {
  PLAYER, PlayerAction
} from '../actions';

const initial_state: Player = {
  paused: true,
  loading_progress: [],
  volume: 0,
  disabled_buttons: {
    next_btn: true,
    prev_btn: true
  },
  show_controlbar: {
    bottom: true,
    top: true
  },
  fullscreen: false,
};

export default function player(state: Player = initial_state, action: PlayerAction){
  switch(action.type) {
    case PLAYER.FULLSCREEN:
      return {
        ...state,
        fullscreen: action.payload
      }
    case PLAYER.SET_VOLUME:
      return {
        ...state,
        volume: action.payload
      }
    case PLAYER.SHOW_CONTROLBAR:
      //@ts-ignore
      if (typeof action.payload === 'object' && action.payload.top === 'any') {
        return {
          ...state,
          show_controlbar: {
            bottom: !state.show_controlbar.bottom,
            top: !state.show_controlbar.top
          }
        }
      }
      return {
        ...state,
        show_controlbar: {
          ...state.show_controlbar,
          ...action.payload
        }
      };
    case PLAYER.SET_DISABLED_BUTTONS:
      return {
        ...state,
        disabled_buttons: {
          ...state.disabled_buttons,
          ...action.payload
        }
      };
    case PLAYER.UPDATE_PROGRESS:
      return {
        ...state,
        loading_progress: action.payload
      };
    case PLAYER.SET_PROGRESS:
      const index = state.loading_progress.findIndex(pr => pr.id === action.payload.id);
      return {
        ...state,
        loading_progress: index !== -1 ? state.loading_progress.map(progress => {
          if (progress.id === action.payload.id) {
            return action.payload;
          }
          return progress;
        })
        :
        state.loading_progress.concat(action.payload)
      }
    case PLAYER.SET_PAUSED:
      return {
        ...state,
        paused: action.payload
      };
    default:
      return state;
  }
};