import React,
{
  useEffect,
  useRef,
  useState
} from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'videojs-contrib-dash';
import { Player } from '../player';
import { host } from '../utils/select-source';
import { showControlBar } from '../state/action-creators';
import { useDispatch } from 'react-redux';

const BANDWIDTH = 4194304 * 19;

export default function usePlayer({
  player_state,
  isSafari,
  isMobile,
  isIos,
  mode
}) {
  const [ready, setReady] = useState<boolean>(false);
  const video = useRef<HTMLVideoElement>(null);
  let player = useRef<VideoJsPlayer>(null);
  const dispatch = useDispatch();

  const config = {
    ios: {
      vhs: {
        overrideNative: false,
        bandwidth: BANDWIDTH
      }
    },
    other: {
      dash: {
        overrideNative: true,
        bandwidth: BANDWIDTH
      }
    }
  }

  const selected_config = config[isIos ? 'ios' : 'other'];
  const onMobileClick = (e) => {
    const target = e.target;
    const className = target.className;
    let client_y = e.clientY;
    if (e.touches) {
      client_y = e.touches[0].clientY;
    }
    const box_height = video.current.offsetHeight;
    const view_rect = video.current.getBoundingClientRect();
    const click_y = client_y - view_rect.top;
    const one = 30;
    const percent = box_height / 100;
    const percents = percent * one;
    const top_range = {
      name: 'top',
      ranges: [0, percents]
    };
    const bottom_range = {
      name: 'bottom',
      ranges: [box_height - percents, box_height]
    };

    for (const _range of [top_range, bottom_range]) {

      const range = _range.ranges;
      if (click_y >= range[0] && click_y <= range[1]) {
        //@ts-ignore
        dispatch(showControlBar({
          [_range.name]: true
        }));
        return;
      }
    }
    dispatch(showControlBar({
      top: false,
      bottom: false,
    }));
  }


  const interaction = (e) => {
    e.preventDefault();
    const _player = Player.getPlayer();
    if (!_player) {
      return;
    }
    const player = _player.player_;
    if (!player) {
      return;
    }

    if (!player.hasStarted()) {
      return player.play();
    }

    if (Player.playing()) {
      player.pause();
      //onMobileClick(e);
      return;
    }
    player.play();
    //onMobileClick(e);
  }

  const another_interaction = (e) => {
    const p = player.current;
    //onMobileClick(e);
    if (p) {
      if (!p.hasStarted() || p.paused()) {
        p.play();
        return;
      }
      p.pause();
    }
  }
  const mob_interaction = (e) => {
    //onMobileClick(e);
    dispatch(showControlBar({
      bottom: false,
      top: false
    }));
  }

  const on_another_interaction = isMobile ? mob_interaction : another_interaction;

  const renderVideo = () => {
    if (player_state && player_state.hasOwnProperty('id')) {
      return (
        <>
          <div
          data-vjs-player
          data-id = { player_state && player_state.id }
          style = {{
            position: 'absolute',
            zIndex: mode === 'another' ? 9998 : 0
          }}
          //onClick = { interaction }
          >
              <video
              crossOrigin = { 'anonymous' }
              //onTouchStart = { isMobile ? interaction : () => {} }
              //onTouchStart = { onMobileClick }
              data-id = { player_state && player_state.id }
              playsInline
              loop = { true }
              autoPlay = { isIos }
              preload = 'auto'
              //muted = { true }
              //id = { player_state && player_state.id }
              ref = { video }
              className = { `video-js u-dont-highlight` }
              style = {{
                cursor: isMobile? 'pointer' : 'unset'
              }}
              >
                
                <track
                src = { `${host}/av1/en/captions/INTRO_NI_ENG.vtt` }
                kind = { 'captions' }
                srcLang = { 'en' }
                label = { 'Eng' }
                ></track>
                <track
                src = { `${host}/av1/ru/captions/INTRO_NI_RUS.vtt` }
                kind = { 'captions' }
                srcLang = { 'ru' }
                label = { 'Rus' }
                ></track>
              </video>
              <div
              onClick = { isMobile ? mob_interaction : mode === 'another' ? on_another_interaction : interaction }
              className = { 'u-dont-highlight' }
              style = {{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: isIos ? 'pointer' : 'unset'
              }}></div>
            </div>
            </>
      )
    }
    return <></>
  }

  useEffect(() => {
  //@ts-ignore
    player.current = videojs(video.current!, {
      controls: false,
      //fill: true,
      bigPlayButton: false,
      controlBar: {
        volumePanel: {
          inline: false
        }
      },
      html5: {
        ...selected_config,
        /*
        vhs: {
          overrideNative: true,
          bandwidth: BANDWIDTH,
        },
        */
        nativeTextTracks: isIos, 
        nativeControlsForTouch: false
      }
    },
    //@ts-ignore
     (onReady: any) => {
      setReady(true);
      player.current.addClass('vjs-skin');
      if (isSafari) {
        player.current.addClass('safari-on');
      }
    });

    return () => {
      player.current.dispose();
      player.current = null;
    };
  }, []);

  return {
    renderVideo,
    player: player.current,
    videoElement: video.current,
    ready
  };
}