import { ReactElement } from 'react';
import style from './control-bar.module.scss';

interface ICbButton {
  callback: Function
  children: ReactElement
}

const CbButton = ({
  callback,
  children
}: ICbButton) => {
  return (
    <button
    className = { style.button }
    style = {{
      width: '100%',
      height: '100%',
      position: 'relative'
    }}
    >
      {
        children
      }
      <div
      //@ts-ignore
      onClick = { callback }
      style = {{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        zIndex: 9999
      }}></div>
    </button>
  )
}

export default CbButton;