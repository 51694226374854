import React, { memo, useState } from 'react';
import CbButton from './cb';
import { Player } from '../../player';
import { ReactComponent as PlayIcon } from '../../images/player/play.svg';
import { ReactComponent as PauseIcon } from '../../images/player/pause.svg';
import BtnVeil from '../buttons/veil';
import Spinner from '../spinner-rounded';
import { VideoJsPlayer } from 'video.js';

interface IPlayButton {
  playing: boolean
  first_interaction: boolean | undefined
  vjs_player?: VideoJsPlayer
}

const animation_style = {
  animation: 'r 1s linear infinite'
}

const PlayButton = ({
  playing,
  first_interaction,
  vjs_player
}: IPlayButton) => {
  const play = () => {
    const is_playing = Player.playing();
    //console.log("is_playing", is_playing);
    if (is_playing) {
      Player.pause();
      return;
    }
    Player.play();
    return;
  }

  const vjs_player_play = () => {
    const is_playing = vjs_player.hasStarted() && !vjs_player.paused();
    if (is_playing) {
      vjs_player.pause();
      return;
    }
    vjs_player.play();
  }

  return (
    <CbButton
    callback = { vjs_player ? vjs_player_play : play }
    >
      <>
      {
        playing ?
        <PauseIcon
        className = { 'def_icon' }
        />
        :
        <PlayIcon
        className = { 'def_icon' }
        style = { false && !first_interaction ? { ...animation_style } : {} }
        />
      }
      {
        false && !first_interaction && <Spinner/>
      }
      </>
    </CbButton>
  )
}

export default memo(PlayButton);