import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import scenario from './scenario';
import { Provider } from 'react-redux';
import store from './state/store';
import 'video.js/dist/video-js.css';
import './app.css';
import './styles/app.scss';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import 'snapsvg-cjs';

const config = {
  active_player_className: '--active'
};

ReactDOM.render(
  <Provider store = { store }>
    <BrowserRouter>
    <App
    scenario = { scenario }
    //@ts-ignore
    config = { config }
    />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
