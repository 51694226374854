import useEventListener from '@use-it/event-listener';
import { Player } from '../player';
import { firstInteraction } from '../state/action-creators';
import { useDispatch } from 'react-redux';

const KeyboardHandler = () => {
  const dispatch = useDispatch();
  useEventListener('keypress', (e) => {
    dispatch(firstInteraction(Date.now()));
    //@ts-ignore
    if (e.code === 'Space') {
      if (!Player.playing()) {
        Player.play();
        return;
      }
      Player.pause();
    }
  })
  return (
    <></>
  );
}

export default KeyboardHandler;