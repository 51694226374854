import {
  PopupProps,
} from '../types';
import Tooltip from './tooltip';

const PopupBtn = ({ label, cb, disabled }) =>
  <button
  disabled = { disabled }
  className = { `popup-btn` } onClick = { cb }>
    { label }
    {
      disabled &&
      <Tooltip
      text = { 'Immersive mode is not available now' }
      direction = { 'bottom' }
      />
    }
  </button>

const Popup: React.FC<PopupProps> = ({
  title,
  actions
}) => {
  return (
    <div
    className = 'popup'
    >
      <div className = 'popup-view'>
        <div className = 'popup-title'>
          <span>
            {
              title
            }
          </span>
        </div>
        <div className = 'popup-actions'>
          {
            actions && Array.isArray(actions) &&
            actions.map((item) => (
              <PopupBtn
              key = { item.label }
              label = { item.label }
              cb = { item.cb }
              disabled = { item.disabled }
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Popup;