const Checkbox = ({
  checked
}) => {
  return (
    <div className = 'checkbox'>
      <div className = 'checkbox-line'>
        {
          checked &&
          <div className = 'checkbox-dot'></div>
        }
      </div>
    </div>
  );
};

export default Checkbox;