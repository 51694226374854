export default function getSeekableSegment(x: number, width: number, duration: number) {
  const step_px = Number((width / duration));
  /*
  console.log('step_px: ', step_px);
  console.log('x: ', x);
  console.log('width: ', width);
  console.log('duration: ', duration);
  */
  let iter = 0;
  let seconds = 0;
  const step = 1000;
  for (let i = 0; i <= duration; i ++) {
    iter += step_px;
    if (iter >= x) {
      seconds = i;
      break;
    }
  }
  return seconds;
}