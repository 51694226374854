import { useEffect, useRef } from 'react';
import Snap from 'snapsvg-cjs';

const LoadingSpinner = () => {
    const svg = useRef(null)
    useEffect(() => {
        if (!svg.current) {
            return;
        }
        try {
            animate()
        }
        catch(error) {
            console.log('error:', error )
        }
    }, [svg]);
    function animate() {
        let s = Snap(svg.current);
    
     let st0 = s.select('.st0');
     let st0_1 = s.select('.st0_1');
     let st0_2 = s.select('.st0_2');
     let st0_white = s.select('.st0_white');
    
     let st1 = s.select('.st1');
     let st1_1 = s.select('.st1_1');
     let st1_2 = s.select('.st1_2');
     let st1_white = s.select('.st1_white');
    
     let st0_points = st0.node.getAttribute('d');
     let st0_1_points = st0_1.node.getAttribute('d');
     let st0_2_points = st0_2.node.getAttribute('d');
    
     let st1_points = st1.node.getAttribute('d');
     let st1_1_points = st1_1.node.getAttribute('d');
     let st1_2_points = st1_2.node.getAttribute('d');
    
    
     let second = function(){
    
       st0.animate({ d: st0_2_points }, 1000, undefined, first);
       st1.animate({ d: st1_2_points }, 1000);
       st0_white.animate({ d: st0_2_points }, 1000);
       st1_white.animate({ d: st1_2_points }, 1000);
     }
    
     let first = function(){
       st0.animate({ d: st0_1_points }, 1000, undefined, second);
       st1.animate({ d: st1_1_points }, 1000);
       st0_white.animate({ d: st0_1_points }, 1000);
       st1_white.animate({ d: st1_1_points }, 1000);
     }
    
     first();
    }
    
    return (
  <div className="loading-spinner">
  <svg
  ref = { svg }
  version="1.1"
       id="loader"
       xmlns="http://www.w3.org/2000/svg"
       x="0px"
       y="0px"
       viewBox="0 0 84 84">
    <path className="st0_white"
          d="M43.7,28.1c-10.1-2.2-12.4-9.5-20.5-9.3S5.2,35.2,11.6,50s7.2,17.9,15.9,19.1c7.4,1,13.3,0.2,20.9-1.3
c2.6-0.5,6.8-1.8,9.1-2.4c14-3.9,28.6-28,8.6-33.7C53.2,28.1,47.4,29,43.7,28.1z"/>
    <path className="st1_white"
          d="M66,29.8c-3.9-4.4-8.8-8.4-16.1-10.4c-7.8-2.1-11.9,8-30.9,15.8C6.8,40.2,8.7,64.5,15,67.9
c7.5,4,19.7-1.7,31-4.2c7-1.6,15,3.1,20.1,1.3c4.8-1.7,11.4-16,5.1-27.4C69.1,33.8,68.8,33,66,29.8z"/>
    <path className="st0"
          d="M43.7,28.1c-10.1-2.2-12.4-9.5-20.5-9.3S5.2,35.2,11.6,50s7.2,17.9,15.9,19.1c7.4,1,13.3,0.2,20.9-1.3
c2.6-0.5,6.8-1.8,9.1-2.4c14-3.9,28.6-28,8.6-33.7C53.2,28.1,47.4,29,43.7,28.1z"/>
    <path className="st1"
          opacity="0.7"
          d="M66,29.8c-3.9-4.4-8.8-8.4-16.1-10.4c-7.8-2.1-11.9,8-30.9,15.8C6.8,40.2,8.7,64.5,15,67.9
c7.5,4,19.7-1.7,31-4.2c7-1.6,15,3.1,20.1,1.3c4.8-1.7,11.4-16,5.1-27.4C69.1,33.8,68.8,33,66,29.8z"/>


    <path opacity="0"
          className="st0_1"
          d="M43.7,28.1c-10.1-2.2-12.4-9.5-20.5-9.3S5.2,35.2,11.6,50s7.2,17.9,15.9,19.1c7.4,1,13.3,0.2,20.9-1.3
c2.6-0.5,6.8-1.8,9.1-2.4c14-3.9,28.6-28,8.6-33.7C53.2,28.1,47.4,29,43.7,28.1z"/>
    <path opacity="0"
          className="st1_1"
          d="M66,29.8c-3.9-4.4-8.8-8.4-16.1-10.4c-7.8-2.1-11.9,8-30.9,15.8C6.8,40.2,8.7,64.5,15,67.9
c7.5,4,19.7-1.7,31-4.2c7-1.6,15,3.1,20.1,1.3c4.8-1.7,11.4-16,5.1-27.4C69.1,33.8,68.8,33,66,29.8z"/>

    <path opacity="0"
          className="st0_2"
          d="M49.6,22.8C39.5,20.6,38.9,21,30.4,21c-8.7,0-13.6,4.4-17.1,16C11,45.2,7,45.8,10.6,58.1
c4.6,15.5,12.9,14.1,16,13.1c3.7-1.2,9.5-7.6,17-9c2.6-0.5,15.3-1.7,17.6-2.1c17.9-3.8,24.2-15.9,8.2-29.1
C62.2,24.9,53.3,23.7,49.6,22.8z"/>
    <path opacity="0"
          className="st1_2"
          d="M57.9,29.2c-9.3-3.8-11.6-9.9-18.9-11.9c-7.8-2.1-15.7-2.8-23.8,5.3C4.4,33.5,10.7,57.4,17,60.8
c5.8,3.2,13.2-1.7,18.8-1.3c4.4,0.3,10.1,2.5,12.1,3c7,1.7,14.2,3.8,19.2,2c6.3-2.3,11.6-19.1,4-26.9C65.7,32.1,61.8,30.8,57.9,29.2
z"/>
    <text><tspan x="10" y="50" className="st4">Loading</tspan></text>
  </svg>
</div>
)
};

export default LoadingSpinner;