import {
  DeviceState
} from '../../types';
import {
  DEVICE,
  DeviceAction
} from '../actions';

const init_state: DeviceState = {
  device: 'desktop',
  os: 'windows',
  browser: 'chrome'
}

export default function device(state = init_state, action: DeviceAction) {
  switch(action.type) {
    case DEVICE.IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload
      };
    case DEVICE.SET_OS:
      return {
        ...state,
        os: action.payload
      };
    case DEVICE.SET_DEVICE:
      return {
        ...state,
        device: action.payload
      };
    case DEVICE.SET_BROWSER:
      return {
        ...state,
        browser: action.payload
      };
    default:
      return state;
  }
}