import Menu from './../menu-btn';
import { useState, useEffect, memo } from 'react';
import { getRepresentationList } from '../../utils/turn-on-quality';
import { Player } from '../../player';
import { Menus } from '../controlbar';
import { VideoJsPlayer } from 'video.js';

const ios_representations = [
  {
    height: 480
  },
  {
    height: 720
  },
  {
    height: 360
  },
  {
    height: 1080
  },
  {
    height: 2160
  }
]

const QualityLevelsMenu = ({
  quality_level,
  language,
  setQualityLevel,
  openedMenu,
  setOpenedMenu,
  menu_id,
  show_bottom_controlbar,
  isIos,
  isMobile,
  isSubMenu,
  vjs_player
}: {
  quality_level: string
  language: 'ru' | 'en'
  setQualityLevel: any
  openedMenu: Menus
  setOpenedMenu: Function
  menu_id: Menus
  show_bottom_controlbar: boolean
  isIos,
  isMobile?: boolean,
  isSubMenu?: boolean,
  vjs_player?: VideoJsPlayer
}) => {
  const [levels, setLevels] = useState([]);
  const [items, setItems] = useState([
    {
      cb() {},
      id: 'NULL',

      //@ts-ignore
      Icon: ({onClick}) => <button onClick = { onClick } style = {{ cursor: 'pointer' }} className = 'vjs-control btn-test vjs-button icon-quality'>
                            <span className = 'vjs-icon-placeholder' aria-hidden = 'true'></span>
                          </button>
    }
  ])
  
  useEffect(() => {

    if (isIos) {
      setLevels(ios_representations);
      return;
    }

    if (levels.length) {
      return;
    }

    const interval = setInterval(() => {
      const _player = vjs_player ? vjs_player : Player.getPlayer();
      if (!_player) {
        return;
      }
      const player = _player.player_;
      const list = getRepresentationList(player, isIos);
      //console.log('isios', isIos, list);
      if (list && list.length) {
        setLevels(list);
        clearInterval(interval);
      }
    }, 500);
  }, []);

  useEffect(() => {
    const si = buildMenu(levels);
    const _items = [
      {
            name: 'settings',
            role: 'menu',
            id: 'settings',
            value: false,
            cb(e) {
              const parents = [e.target.parentElement.parentElement];
              const opened_class = '--opened-menu';
              const closed_class = '--closed-menu';

              parents.forEach(parent => {
                if (parent.className.includes(opened_class)) {
                  parent.classList.add(closed_class);
                  setTimeout(() => {
                    parent.classList.remove(opened_class);
                  }, 200);
                }
                else {
                  parent.classList.remove(closed_class);
                  parent.classList.add(opened_class);
                }
              });

            },
            //@ts-ignore
            Icon: ({onClick}) => <button onClick = { onClick } style = {{ cursor: 'pointer' }} className = 'vjs-control btn-test vjs-button icon-quality'>
                      <span className = 'vjs-icon-placeholder' aria-hidden = 'true'></span>
                    </button>
          },
      ...si
    ];
    setItems(_items);
  }, [levels]);

  function buildMenu(levels) {
  const sub_items = [];
    for (let x = 0; x < levels.length; x ++) {
      const level = levels[x];
      if (!level || (level && !level.height)) {
        continue;
      }
      const height = level.height;
      const item = {
        name: `${height}p`,
        value: height,
        id: height.toString(),
        cb: handleOnItemClick,
        label: getRepLabel(height),
        selected: height.toString() === quality_level,
        checkbox: {
          checked: height.toString() === quality_level
        },
      }
      if (
        !sub_items.find(i => i.value === item.value)
      ) {
        sub_items.push(item);
      }
    }
    const auto_btn = {
      name: 'Auto',
      value: 'auto',
      id: 'auto',
      cb: handleOnItemClick,
      label: 'auto',
      selected: 'auto' === quality_level,
      checkbox: {
        checked: 'auto' === quality_level
      }
    };
    return [auto_btn, ...sub_items.sort((a, b) => b.value - a.value)];
  }
  const handleOnItemClick = (e) => {
    const el = e.target;
    const id = el.getAttribute('data-id');
    if (!id) {
      return;
    }
    setQualityLevel(id);
  }

  function getRepLabel(rep): string {
    if (rep > 400 && rep < 500) {
      return 'Med';
    }
    if (rep > 700 && rep < 800) {
      return 'HD';
    }
    if (rep > 1000 && rep < 1100) {
      return 'FHD';
    }
    if (rep > 2000) {
      return '4k'
    }
    if (rep > 300 && rep < 400) {
      return 'Low';
    }
    return 'Auto';
  }

  return (
    <Menu
    type = { 'default' }
    selected = { quality_level }
    label = { 'Video quality' }
    //@ts-ignore
    items = { items }
    language = { language }
    //@ts-ignore
    Icon = { items[0] ? items[0].Icon : () => <span></span> }
    openedMenu = { openedMenu }
    setOpenedMenu = { setOpenedMenu }
    id = { menu_id }
    show_bottom_controlbar = { show_bottom_controlbar }
    isMobile = { isMobile }
    isSubMenu = { isSubMenu }
    />
  )
}

export default memo(QualityLevelsMenu);