import {
  PlayerState,
  Segment,
  RootState,
  Root,
  Mode,
  ViewType,
  TimelineState,
  QualityLevel,
  RewindingState,
  CurrentTime,
  PlayerProgress,
  Buttons,
  PopupProps,
  Language,
  ExtendedVideoJsPlayer
} from '../types';

export enum SEGMENT {
  SET_VOICE_RECOGNITION_ENABLED = 'SET_VOICE_RECOGNITION_ENABLED',
  SET_CHOOSE_MOOD_ENABLED = 'SET_CHOOSE_MOOD_ENABLED'
}

export enum PLAYER {
  SET_PAUSED = 'SET_PAUSED',
  SET_PROGRESS = 'SET_PROGRESS',
  UPDATE_PROGRESS = 'UPDATE_PROGRESS',
  SET_DISABLED_BUTTONS = 'SET_DISABLED_BUTTONS',
  SHOW_CONTROLBAR = 'SHOW_COTNTROLBAR',
  SET_VOLUME = 'SET_VOLUME',
  FULLSCREEN = 'FULLSCREEN'
}

export enum GLOBAL {
  SET_SCNERATIO = 'SET_SCENARIO',
  CHANGE_ACTIVE_PLAYER = 'CHANGE_ACTIVE_PLAYER',
  SET_SEGMENT_FOR_ACTIVE_PLAYER = 'SET_SEGMENT_FOR_ACTIVE_PLAYER',
  NEXT_SEGMENT = 'NEXT_SEGMENT',
  PREV_SEGMENT = 'PREV_SEGMENT',
  UPDATE_STATE = 'UPDATE_STATE',
  SET_MODE = 'SET_MODE',
  SET_VIEW_TYPE = 'SET_VIEW_TYPE',
  REGISTER_PLAYER = 'REGISTER_PLAYER',
  UNREGISTER_PLAYER = 'UNREGISTER_PLAYER',
  SET_QUALITY_LEVEL = 'SET_QUALITY_LEVEL',
  SET_RESERVED_PLAYER_STATE = 'SET_RESERVED_PLAYER_STATE',
  SET_REWINDING = 'SET_REWINDING',
  SET_FOUND_SEGMENT = 'SET_FOUND_SEGMENT',
  SET_SHOW_BG = 'SET_SHOW_BG',
  SHOW_POPUP = 'SHOW_POPUP',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SHOW_SUBTITLES = 'SHOW_SUBTITLES',
  SLOW_INTERNET_CONNECTION = 'SLOW_INTERNET_CONNECTION',
  SET_TRANSITION_PLAYER = 'SET_TRANSITION_PLAYER',
  FIRST_INTERACTION = 'FIRST_INTERACTION'
}

export enum TIMELINE {
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SET_TIMELINE = 'SET_TIMELINE',
  SET_DURATION = 'SET_DURATION',
  SET_SEKKED_TIME = 'SET_SEEKED_TIME',
}

export enum DEVICE {
  SET_BROWSER = 'SET_BROWSER',
  SET_OS = 'SET_OS',
  SET_DEVICE = 'SET_DEVICE',
  IS_MOBILE = 'IS_MOBILE'
}

export interface ShowSubtitlesPayload {
  show: boolean
  language: Language
}

export type DeviceAction = 
  {
    type: DEVICE.IS_MOBILE,
    payload: boolean
  }
  |
  {
    type: DEVICE.SET_BROWSER,
    payload: string
  }
  |
  {
    type: DEVICE.SET_DEVICE,
    payload: string
  }
  |
  {
    type: DEVICE.SET_OS,
    payload: string
  }

export type SegmentAction = 
  {
    type: SEGMENT.SET_VOICE_RECOGNITION_ENABLED,
    payload: boolean
  }
  |
  {
    type: SEGMENT.SET_CHOOSE_MOOD_ENABLED,
    payload: boolean
  }

export type TimelineAction = 
  {
    type: TIMELINE.SET_CURRENT_TIME,
    payload: CurrentTime,
    rate_limit?: number
  }
  |
  {
    type: TIMELINE.SET_TIMELINE,
    payload: TimelineState
  }
  |
  {
    type: TIMELINE.SET_DURATION,
    payload: number
  }
  |
  {
    type: TIMELINE.SET_SEKKED_TIME,
    payload: number | undefined,
    rate_limit?: number
  }

export type PlayerAction =
  {
    type: PLAYER.SET_PAUSED,
    payload: boolean
  }
  |
  {
    type: PLAYER.SET_PROGRESS,
    payload: PlayerProgress,
    rate_limit?: number
  }
  |
  {
    type: PLAYER.UPDATE_PROGRESS,
    payload: PlayerProgress[]
  }
  |
  {
    type: PLAYER.SET_DISABLED_BUTTONS,
    payload: { next_btn: boolean } | { prev_btn: boolean }
  }
  |
  {
    type: PLAYER.SHOW_CONTROLBAR,
    payload: { top: boolean | 'any' } | { bottom: boolean | 'any' },
    rate_limit?: number
  }
  |
  {
    type: PLAYER.SET_VOLUME,
    payload: number
  }
  |
  {
    type: PLAYER.FULLSCREEN,
    payload: boolean
  }

export type GlobalAction =
  {
    type: GLOBAL.SET_SCNERATIO,
    payload: Segment[]
  }
  |
  {
    type: GLOBAL.CHANGE_ACTIVE_PLAYER,
    payload: string,
    rate_limit?: number
  }
  |
  {
    type: GLOBAL.SET_SEGMENT_FOR_ACTIVE_PLAYER,
    payload: string
  }
  |
  {
    type: GLOBAL.NEXT_SEGMENT,
    payload: string,
    rate_limit: number
  }
  |
  {
    type: GLOBAL.PREV_SEGMENT,
    payload: string
  }
  |
  {
    type: GLOBAL.UPDATE_STATE,
    payload: Root
  }
  |
  {
    type: GLOBAL.SET_MODE,
    payload: Mode
  }
  |
  {
    type: GLOBAL.SET_VIEW_TYPE,
    payload: ViewType
  }
  |
  {
    type: GLOBAL.REGISTER_PLAYER,
    payload: ExtendedVideoJsPlayer
  }
  |
  {
    type: GLOBAL.UNREGISTER_PLAYER,
    payload: string
  }
  |
  {
    type: GLOBAL.SET_QUALITY_LEVEL,
    payload: QualityLevel
  }
  |
  {
    type: GLOBAL.SET_RESERVED_PLAYER_STATE,
    payload: PlayerState
  }
  |
  {
    type: GLOBAL.SET_REWINDING,
    payload: RewindingState,
    rate_limit?: number
  }
  |
  {
    type: GLOBAL.SET_FOUND_SEGMENT,
    payload: Segment
  }
  |
  {
    type: GLOBAL.SET_SHOW_BG,
    payload: boolean
  }
  |
  {
    type: GLOBAL.SHOW_POPUP,
    payload: PopupProps | undefined
  }
  |
  {
    type: GLOBAL.SHOW_SUBTITLES,
    payload: ShowSubtitlesPayload
  }
  |
  {
    type: GLOBAL.SET_LANGUAGE,
    payload: Language
  }
  |
  {
    type: GLOBAL.SLOW_INTERNET_CONNECTION,
    payload: boolean
  }
  |
  {
    type: GLOBAL.SET_TRANSITION_PLAYER,
    payload: PlayerState
  }
  |
  {
    type: GLOBAL.FIRST_INTERACTION,
    payload: number
  }
export type AppAction = GlobalAction | PlayerAction | TimelineAction | SegmentAction | DeviceAction;