import { Slider, Rail, SliderItem, GetTrackProps, Tracks } from 'react-compound-slider';
import { Player } from '../../player';
import { useRef } from 'react';

interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  dimensions: {
    width: number
    height: number
  }
}

export const Track: React.FC<ITrackProps> = ({
  source,
  target,
  getTrackProps,
  dimensions
}) => (
  <div
  className = { 'u-volume' }
    style={{
      position: 'absolute',
      zIndex: 1,
      width: dimensions.width,
      backgroundColor: 'rgba(161,161,160,255)',
      cursor: 'pointer',
      top: `${source.percent}%`,
      height: `${target.percent - source.percent}%`,
    }}
    {...getTrackProps()}
  />
);

const sliderStyle: React.CSSProperties = {
  position: 'relative',
  //marginLeft: '37%',
  //width: '100%',
  height: '120px'
};

const railStyle: React.CSSProperties = {
  position: 'absolute',
  width: '40px',
  height: '100%',
  cursor: 'pointer',
  backgroundColor: 'rgb(67,67,66,255)',
  display: 'flex',
  justifyContent: 'center'
};

const domain: number[] = [0, 100];

export default function({
  volume,
  setVolume,
  dimensions,
  player
}) {
  const slider_container = useRef(null);
  const onChange = (values: number[]) => {
    player.volume(volume);
    setVolume(values[0]);
  };
  const { width, height } = dimensions;

  const onMove = (e) => {
    const y = e.clientY;
    const slider = slider_container.current
    const rect = slider.getBoundingClientRect();
    const position = y - rect.top;
    slider.style.setProperty('--y', position + 'px');
  }

  const onClick = (e) => {
    const slider = slider_container.current
    const rect = slider.getBoundingClientRect();
    const height = rect.height;
    const position = parseInt((e.clientY - rect.top).toFixed(2));
    const percent = calc(height, e.clientY - rect.top);
    setVolume(100 - percent);
  }

  function calc(height: number, click_y: number) {
    return click_y / (height / 100);
  }

    return (
      <div
      onClick = { onClick }
      className = { 'volume-range-slider-container' }
      ref = { slider_container }
      onMouseMove = { onMove }
      style = {{
        display: 'flex',
        justifyContent: 'center',
        width,
        position: 'relative'
      }}
      >
        <Slider

          mode={1}
          step={1}
          reversed={true}
          vertical={true}
          domain={domain}
          rootStyle={{
            ...sliderStyle,
            ...dimensions
          }}
          onChange={onChange}
          values={[volume || 0]}
        >
          <Rail>
            {({ getRailProps }) => (
              <div style={{
                ...railStyle,
                width,
              }} {...getRailProps()} />
            )}
          </Rail>
          <Tracks left={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track

                    dimensions = { dimensions }
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    );
  }