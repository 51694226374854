export default function l(player: any, height: number | 'auto') {
  const diff = 50;
  const height_on_slow_connection = 480;
  const height_default = 1080;
  //@ts-ignore
  const slow_internet_connection = window.slow_internet_connection;
  if (
    player &&
    player.tech(false) &&
    player.tech(false).vhs &&
    player.tech(false).vhs.representations()
  ) {
    player.tech(false).vhs.representations()
      .forEach((represenation: any) => {

        if (
          represenation &&
          represenation.height &&
          represenation.enabled
        ) {
          if (height === 'auto') {
            if (slow_internet_connection) {
              if (represenation.height === height_on_slow_connection) {
                represenation.enabled(true);
              }
              else {
                represenation.enabled(false);
              }
            }
            else {
              if (represenation.height === height_default) {
                represenation.enabled(true);
              }
              else {
                represenation.enabled(false);
              }
            }
            return;
          }
          if (
            represenation.height - height <= diff &&
            represenation.height - height >= -diff
            ) {
              represenation.enabled(true);
            }
            else {
              represenation.enabled(false);
            }
        }
      });
  }
}

export function getRepresentationList(player: any, isIos?: boolean) {
  if (isIos && player) {
    if (player && player.tech(false) && player.tech(false).vhs && player.tech(false).represenations) {
      return player.tech(false).vhs.representations();
    }
  }
  if (
    player &&
    player.qualityLevels
  ) {
    return player.qualityLevels();
  }
}