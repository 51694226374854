import React, {
  useEffect,
  useState,
  useRef,
  ReactElement
} from "react";
import Tooltip from './tooltip';
import Checkbox from './checkbox';
import BtnVeil from './buttons/veil';
import { Language } from "../types";
import { Menus } from "./controlbar";
import useOnClickOutside from "../hoc/use-click-outside";

export interface MenuBtnProps {
  items: Item[]
  Icon: any
  selected?: string | null
  vjsComponent?: any
  label?: string
  language?: Language
  type?: 'default' | 'simple'
  subscribe_to_store_value?: any
  subscribed_value?: any
  openedMenu: Menus
  id: Menus
  show_bottom_controlbar: boolean
  setOpenedMenu: Function,
  isIos?: boolean
  isMobile?: boolean
  isSubMenu?: boolean
  show_subs?: boolean
}

export interface Item {
  id: string
  name?: string
  cb: Function
  value?: any
  Icon?: any
  role?: string
  items?: Item[]
  selected?: boolean
  checkbox?: any
  label?: string
}

const content = {
  ru: {
    back: 'назад',
    quality_label: 'Качество видео'
  },
  en: {
    back: 'back',
    quality_label: 'Video quality'
  }
}

const MenuBtn: React.FC<MenuBtnProps> = ({
  items,
  Icon,
  selected,
  language,
  label,
  type,
  openedMenu,
  id,
  show_bottom_controlbar,
  setOpenedMenu,
  isMobile,
  isSubMenu,
  show_subs
}) => {
  const [state, setState] = useState(items);
  const [showTooltip, setShowTooltip] = useState(false);
  const btn_list = useRef<HTMLDivElement>(null);
  
  let opened_class = '--opened-menu';
  let closed_class = '--closed-menu';

  if (isMobile && isSubMenu) {
    opened_class = '--opened-menu-sub';
    closed_class = '--closed-menu-sub';
  }

  useEffect(() => {
    setState(items);
    
  }, [items]);
  
  useEffect(() => {
    if ((openedMenu !== id) || !show_bottom_controlbar) {
      closeMenu();
    }
  }, [openedMenu, show_bottom_controlbar]);

  useEffect(() => {
    setState(state.map(item => ({
      ...item,
      //@ts-ignore
      selected: (is_subtitles_menu && show_subs) ? (item.id.toLowerCase().includes(selected)) : item.id === selected,
      checkbox: {
        //@ts-ignore
        checked: (is_subtitles_menu && show_subs) ? (item.id.toLowerCase().includes(selected)) : item.id === selected
      }
    })));
  }, [selected]);

  const handleOnMouseMove = (e) => {
    if (!isMenuOpened) {
      setShowTooltip(true);
    }
    else {
      setShowTooltip(false);
    }
  };

  const handleOnMouseLeave = (e) => {
    setShowTooltip(false);
  };

  const closeMenu = () => {
    
    if (btn_list && btn_list.current) {
      if (openedMenu === id) {
        setOpenedMenu(undefined);
      }
      if (btn_list.current.className.includes(opened_class)) {
        btn_list.current.classList.add(closed_class);
        btn_list.current.classList.remove(opened_class);
      }
    }
  }

  const handleOnClick = (e, cb) => {
    const elem_id = e.currentTarget.getAttribute('data-id');

    if (!elem_id) {
      cb(e);
      return;
    }
    cb(e);
  };


  const isMenuOpened = () => {
    const element = document.getElementById('menu-btn-list');
    if (element && element.classList.contains(opened_class)) {
      return true;
    }
    return false;
  };
  const handleBackIcon = (e) => {
    const el = document.getElementById('menu-btn');
    if (el && el.className.includes(opened_class)) {
      el.classList.add(closed_class);
      setTimeout(() => {
        el.classList.remove(opened_class);
      }, 150);
    }
    else {
      el.classList.add(opened_class);
      el.classList.remove(closed_class);
    }
  }

  const openMenu = () => {
    const element = btn_list.current;

    if (element.className.includes(opened_class)) {
      setOpenedMenu('');
      element.classList.add(closed_class);
      return element.classList.remove(opened_class);
    }
    setOpenedMenu(id);
    element.classList.add(opened_class);
    return element.classList.remove(closed_class);
  }
  const condition = isMobile && isSubMenu && !id.includes('quality');
  const list_style = {};
  if (condition) {
    //@ts-ignore
    list_style.height = '80px';
  }
  const is_subtitles_menu = label.toLowerCase().includes('subtitles');
  const renderMenu = (items: Item[], isSubMenu?: boolean) => {
    return (
      <div
      id = 'menu-btn-list'
      className = { `menu-btn-items-list ${is_subtitles_menu ? 'subs-menu' : ''}` }>
        {
          false &&
          <div className = 'menu-btn-back'>
            <span>
              {
                content[language].back
              }
            </span>
            <BtnVeil
            onClick = { handleBackIcon }
            value = { 'value' }
            />
          </div>
        }
        {
          label &&
          <div className = 'menu-btn-label'>
            <span>
              { label }
            </span>
          </div>
        }
          <div
          style={ {...list_style} }
          className = 'menu-btn-items-list-wrapper'>
            <table>
            <tbody>
          {
            items && Array.isArray(items) &&
            items.slice().map((item, i) => 
            { return (

              <tr
              key = { item.id }
              data-id = { item.value }
              id = { item.id }
              //@ts-ignore
              //onClick = { (e) => handleOnClick(e, item.cb) }
              style = {{
                position: 'relative',
              }}
              className = { `menu-btn-item
                ${item.role === 'menu' ? '--not-hoverable' : '' }
                ${item.selected ? '--selected' : ''}
                `}
                >
                {
                  item.checkbox &&
                  <td>
                    <Checkbox
                    checked = { item.checkbox.checked }
                    />
                  </td>
                }
                {
                  item.name &&
                  <td>
                  <span
                  className = 'menu-btn-item-value'
                  //@ts-ignore
                  data-id = { item.value }
                  >
                    { item.name }
                  </span>
                  </td>
                }
                {
                  item.label && 
                  <td>
                  <span
                  className = 'menu-btn-item-label'
                  data-id = { item.value }
                  >
                    { item.label }
                  </span>
                  </td>
                }
                {
                  item.Icon &&
                  <td>
                  <span
                  style = {{
                    display: 'block'
                  }}
                id = { item.id }
                className = 'menu-btn-item-icon'>

                  <item.Icon
                  //@ts-ignore
                  //onClick = { item.cb }
                  value = { item.value }
                  />
                  
                </span>
                </td>
                }
                {
                  item.role === 'menu' &&
                  renderMenu(item.items, true)
                }
                <BtnVeil
                value = { item.value }
                onClick = { (e) => {
                  handleOnClick(e, item.cb)
                } }
                />
              </tr>
            )})
            
          }
          </tbody>
          </table>
          </div>
        </div>
    );
  }

  useOnClickOutside(btn_list, (e) => {
    closeMenu();
  });

  return (
      <div
      ref = { btn_list } 
      id = 'menu-btn'
      className = { `menu-btn vjs-control ${type === 'simple' ? 'menu-btn--simple' : ''}` }>
        <div
        className = 'menu-btn-icon-container'>
          <Icon/>
          {
            showTooltip &&
            <Tooltip
            text = { content[language].quality_label }
            direction = 'top'
            />
          }
        </div>
        {
          renderMenu(type !== 'simple' ? state.slice(1) : state)
        }
        <BtnVeil
        onClick = { openMenu }
        onMouseMove = { handleOnMouseMove }
        onMouseLeave = { handleOnMouseLeave }
        />
      </div>
  );
};

export default MenuBtn;