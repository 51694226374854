import usePlayer from './use-player';
import {
  RootState,
} from '../types';
import {
  useEffect,
  useState
} from 'react';
import {
  nextSegment,
  setRewinding,
  setDuration,
  setPaused,
  setQualityLevel,
  setReservedPlayerState,
  setActivePlayer,
  setFoundSegment,
  setDisabledButtons,
  setShowBg,
  showControlBar,
  setLanguage,
  showSubtitles,
  setScenario,
  setTransitionPlayer,
  setVolume,
  registerPlayer,
  unregisterPlayer,
  setCurrentTime,
  setTimeline
} from '../state/action-creators';
import {
  useAppDispatch
} from '../state/hooks';
import { connect } from 'react-redux';
import PlayerController from '../player/controller';
import 'videojs-contrib-quality-levels';
import store from '../state/store';
import turnOnQuality from '../utils/turn-on-quality';
import selectSource from '../utils/select-source';
import getIosSource from '../utils/ios-source';
import { Player as P } from '../player';

//@ts-ignore
window.active_player_z_index = 300;

const dash_type = 'application/dash+xml';
const hls_type = 'application/x-mpegURL';

const custom_waiting_class = 'custom-waiting';

function Player({
  active_player_id,
  label,
  setVolume,
  volume,
  setFoundSegment,
  reserved_player,
  setPaused,
  setActivePlayer,
  quality_level,
  found_segment,
  setReservedPlayerState,
  setDisabledButtons,
  rewinding,
  setRewinding,
  language,
  subtitles,
  isSafari,
  setTransitionPlayer,
  transition_player,
  first_interaction,
  isIos,
  setCurrentTime,
  setTimeline,
  isMobile,
  src,
  mode
}) {
  const default_state = {
    id: '',
    label,
    segment: {
      id: '',
      duration: 0,
      subtitles: '',
      default: false,
      linked_with: [],
      src: '',
      dropped_on: 0
    }
  };

  const [firstLoad, setFirstLoad] = useState(true);
  const [status, setStatus] = useState(undefined);
  const [state, setState] = useState<typeof default_state>(default_state);
  const [existed_player_id, set_existed_player_id] = useState('');
  const [started_at, set_started_at] = useState<null | number>(null);

  const dispatch = useAppDispatch();
  let {
    player,
    renderVideo,
    videoElement,
    ready
  } = usePlayer({
    player_state: state,
    isSafari,
    isMobile,
    isIos,
    mode
  });
/*
  useEffect(() => {
    P.getPlayers().map(player => {
      console.log(`id: ${player.player.id()} | label: ${player.label} | paused: ${player.player.paused()}`)
    })

  });
*/
  useEffect(() => {
    if (!ready) return;
    checkTracks();
    if (active_player_id === state.id) {
      const player_ = P.getPlayer();
      if (!player_) {
        return;
      }
      if (!player_.el) {
        return;
      }
      const element = player_.el();  
      showPlayer(element);
      player.play();
    }
    else {
      if (
        player && !player.paused()
      ) {
        player.pause();
      }
    }
  }, [active_player_id, state.id, ready]);
  /*
  useEffect(() => {
    if (!player) return;
    if (!paused && active_player_id !== state.id) {
      //console.log('MANUALLY PAUSED:', state.id)
      player.pause();
    }
  });
  */
  /*
  useEffect(() => {
    if (!player) {
      return;
    }
    if (active_player_id === state.id && isPaused()) {
      console.log('useEffect[mode] player.play()', state.id);
      if (!isSafari) {
        //player.play();
      }
    }
  }, [mode]);
  */
  useEffect(() => {
    if (!player) return;
    if (
      active_player_id === state.id
      ) {
      player.off('ended', handleOnEnd);
      player.one('ended', handleOnEnd);
    }
  }, [active_player_id, state.id]);

  const handleOnEnd = () => {
        if (store.getState().root.active_player_id !== 'NI') {
          //@ts-ignore
          dispatch(nextSegment());
        }
        if (label === 'splitting') {
          updateState();
        }
    }
  
  useEffect(() => {
    if (!player) return;
      player.on('pause', handlePlayerState);
      player.on('play', handlePlayerState);
  }, [player]);
  
  useEffect(() => {
    if (!active_player_id) return;
    updateState();
    setDisabledButtons({
      next_btn: true,
      prev_btn: true,
    });
  }, [active_player_id]);
  /*
  useEffect(() => {
  	if (!player) {
  		return;
  	}
    if (active_player_id === state.id) {
      return;
    }
    if (
      (next_default_segment && next_default_segment.id === state.id) ||
      (prev_segment && prev_segment.id === state.id)
      ) {
      if (PlayerController._isNormalOrSplittingSegmentPlaying(active_player_id)) {
        return;
      }
      if (mode === 'initial' && state.id === '1D') {
        return;
      }
      if (
        (next_default_segment && next_default_segment.id.includes('NI')) ||
        (prev_segment && prev_segment.id.includes('NI'))) {
        return;
      }
    
      const interval = setInterval(() => {

        const check = checkProgress(1.8);
        const key = next_default_segment.id === state.id ? 'next_btn' : 'prev_btn';
        if (check) {

          setDisabledButtons({
            [key]: false
          });
          player.currentTime(0);
          clearInterval(interval);
        }
      }, 100);
    }
  }, [next_default_segment, prev_segment, state.id]);
  */
  useEffect(() => {
    if (!player) {
      return;
    }
    const player_el = player.el();
    
    if (ready && state.id && state.segment) {
      player.on('volumechange', (e) => {
        console.log('vol changed', player.volume());
      })
      console.log(`<<<- Playing now - ${state.id} ->>>`);
      player_el.setAttribute('data-id', state.id);
      /*
      player.one('loadedmetadata', () => {
        const global_state = store.getState();
        if (global_state.root.show_bg && global_state.root.active_player_id === state.id) {
          setTimeout(() => {
            setShowBg(false);
          }, 500);
        }
      });
      */
      const type = isIos ? hls_type : dash_type;
      const source = src ? src : state.segment.src;
      player.src(
        {
          src: source,
          type: type,
        },
      );
      /*
      if (isIos && typeof state.segment.dropped_on === 'number') {
        player.currentTime(state.segment.dropped_on);
      }
      */
    }
    /*
    if (typeof started_at !== 'number') {
      player.one('play', (e) => {
        console.log('play', e);
        set_started_at(Date.now());
      });
    }
    */
  }, [ready, state.id]);
  
  useEffect(() => {
    if (!ready || !player || !state.id) return;

    if (isIos) {
      const source = getIosSource(language, quality_level);
      const new_src = {
        src: source
      };
      player.addClass(custom_waiting_class);
      player.pause();
      setTransitionPlayer({
        ...state,
        id: state.id + '_' + quality_level,
        action: 'switch_quality',
        volume,
        segment: {
          ...state.segment,
          src: new_src.src,
          dropped_on: player.currentTime() * 1000
        }
      });
      return;
    }

    turnOnQuality(player, Number(quality_level));
  }, [quality_level]);

  const handlePlayerState = (event) => {
    if (!player || !event) {
      return;
    }

    if (event.type === 'pause') {
      //console.log('puse: ', event);
      setPaused(true);
    }
    if (event.type === 'play') {
      setPaused(false);
    }
  };
  
  const updateState = () => {
    if (first_interaction) {
      return;
    }
    
    if (
      state.label === 'reserved' ||
      state.label === 'transition'
    ) {
      return;
    }
    if (
      PlayerController._isNormalOrSplittingSegmentPlaying(active_player_id) &&
      !label.includes('splitting')
      ) {
      //return;
    }
    const update = PlayerController.requestPlayerUpdate(state.label, state.id);
    //console.log('update,', update, label, state.id);
    if (update && update.not_need_update) {
      return;
    }
    if (!update) {
      if (state.id) {
        setState(default_state);
      }
      return;
    }
    if (
      update &&
      active_player_id !== state.id &&
      //@ts-ignore
      update.id !== state.id
    ) {
      //@ts-ignore
      setState(update);

    }
    if (
      update &&
      active_player_id === state.id
    ) {
      PlayerController.saveAbortedPlayer(update);
    }
    if (
      update &&
      update.segment &&
      active_player_id === update.segment.id &&
      firstLoad
    ) {
      if (update.id) {
        delete update.not_need_update;
        //@ts-ignore
        setState(update);
        /*
        registerPlayer({
          player_id: update.id,
          player_label: label
        });
        */
        setFirstLoad(false);
      }
    }
  };

  useEffect(() => {
    /*
    if (label !== 'reserved') {
      if (state.id === reserved_player.id) {
        setTimeout(() => {
          setState(default_state);
        }, 3000);
      }
      return;
    }
    */
    if (
      reserved_player &&
      reserved_player.id &&
      state.id !== reserved_player.id
      ) {
      //console.log('<---RESERVED PLAYER INIT')
      //@ts-ignore
      setState(reserved_player); 
      player.one('loadeddata', () => {
        const active_player_id = store.getState().root.active_player_id;
        if (active_player_id !== state.id) {
          if (reserved_player.segment.dropped_on) {
            //console.log('dropped on: ', reserved_player.segment.dropped_on)
            player.currentTime(reserved_player.segment.dropped_on / 1000);
          }
          const id = reserved_player.id.slice();
            const interval = setInterval(() => {
              const check = checkProgress(reserved_player.segment.dropped_on / 1000 || 0);
              if (check) {
                setRewinding({
                  actual: false,
                  seconds: 0
                });
                
                setReservedPlayerState(undefined);
                player.play();
                setTimeout(() => {
                  setActivePlayer(id);
                }, 100);
                clearInterval(interval);
              }
            }, 200);
          
        }
      });
    }
  }, [reserved_player]);

  useEffect(() => {
    if (found_segment && found_segment.id === state.id) {
      player.currentTime(found_segment.dropped_on / 1000);
      const interval = setInterval(() => {
        const check = checkProgress(found_segment.dropped_on / 1000);
        if (check) {
          setRewinding({
            actual: false,
            seconds: 0
          });
          player.play();
          setTimeout(() => {
            setActivePlayer(state.id);
          }, 100);
          setFoundSegment(undefined);
          clearInterval(interval);
        }
      }, 200);
    }
  }, [found_segment]);

  useEffect(() => {
    if (!player || active_player_id !== state.id) {
      return;
    }

    if (rewinding && rewinding.actual) {
      //console.log('[rewinding] player.pause()');
      player.addClass(custom_waiting_class);
      player.pause();
    }

    if (rewinding && !rewinding.actual) {
      player.removeClass(custom_waiting_class);
      //console.log('[rewinding] player.play()');
      setTimeout(() => {
        if (store.getState().root.active_player_id === state.id && isPaused()) {
          player.play();
        }
      }, 150);
    }
  }, [rewinding]);

  const checkProgress = (seconds: number) => {
    const buffered = player.bufferedEnd();
    //console.log('id: ', state.id, 'buffered: ', buffered);
    if (buffered >= seconds) {
      return true;
    }
    return false;
  };
  
  const isPaused = () => player.paused();

  const showPlayer = (element) => {
    /*
    if (label === 'normal') {
      return;
    }
    */
    if (element) {
      //@ts-ignore
      const next_zIndex = window.active_player_z_index + 1;
      element.style.zIndex = next_zIndex;
      //@ts-ignore
      window.active_player_z_index = next_zIndex;
    }
  };
  
  /*
  useEffect(() => {
    if (!player) return;
    //@ts-ignore
    console.log('___DEBUG___ ', player.log.history());
    
  }, [active_player_id]);
  */
  function checkTracks() {
    const tracks = player.textTracks();
    let player_with_enabled_tracks = undefined;

    for (let x = 0; x < tracks.length; x ++) {
      const track = tracks[x];

      if (subtitles.show) {
        if (track.language === subtitles.language && !player_with_enabled_tracks) {
          track.mode = 'showing';
          player_with_enabled_tracks = true;
        }
        else {
          track.mode = 'hidden'
        }
      }
      else {
        track.mode = 'hidden';
      }
    }
  }

  useEffect(() => {
    if (!player) return;

    checkTracks();
  }, [subtitles, language]);

  useEffect(() => {
    
    const new_src = {
      src: selectSource(language, isSafari, quality_level, false, isIos),
      type: isIos ? hls_type : dash_type
    }
    /*
    const existed_player = P.findExistedPlayer(new_src.src);
    console.log('_existed_player: ', existed_player);
    if (existed_player) {
      set_existed_player_id(existed_player.id());
      return;
    }
    */
    if (!player || active_player_id !== state.id || state.label === 'reserved' || state.label === 'transition') {
      //console.log(`${active_player_id} !== ${state.id}`, state, label)
      return
    }
    player.addClass(custom_waiting_class);
    player.pause();
    setTransitionPlayer({
      ...state,
      id: replaceLang(state.id, language),
      action: 'switch_language',
      volume,
      segment: {
        ...state.segment,
        src: new_src.src,
        dropped_on: player.currentTime() * 1000
      }
    });
    setStatus('in_transition');
    console.log('SET_TRANSITION_PLAYER', state.id, state.label)
  }, [language])

  useEffect(() => {
    if (!player) {
      return;
    }

    if (transition_player) {
      if (state.label === 'transition') {
        //console.log('LABEL ', transition_player.id, state.label)
        setState(transition_player);
        /*
        console.log('registering', transition_player.id);
        registerPlayer({
          player_id: transition_player.id,
          player_label: 'transition'
        });
        */
        player.addClass(custom_waiting_class);
        //player.controlBar.addClass('u-on-bottom');
        /*
        if (isIos) {
        	player.removeClass(custom_waiting_class);
        	setActivePlayer(transition_player.id);
          setTransitionPlayer(null);
        	return;
        }
        */
        player.one('loadeddata', () => {
          player.volume(transition_player.volume);
          if (transition_player.segment.dropped_on) {
            player.currentTime(transition_player.segment.dropped_on / 1000);
            player.one('timeupdate', () => {
              const timeout = setTimeout(() => {
                //player.controlBar.removeClass('u-on-bottom');
                player.removeClass(custom_waiting_class);
                setActivePlayer(transition_player.id);
                //showPlayer(player.el());
                setTransitionPlayer(null);
                clearTimeout(timeout);
              }, 150);
            })
          }
        })
      }
    }
    
    //@ts-ignore
    if (transition_player === null && status === 'in_transition') {
      //console.log("transition_player === null && status === 'in_transition'", state);
      if (player) {
        player.removeClass(custom_waiting_class);
      }
      //unregisterPlayer(state.id);
      setState({
        ...default_state,
        label: 'transition'
      });
      console.log('souce', state.id, state.label)
      player.src('x');
      setStatus(undefined);
    }
    
  }, [transition_player])

  useEffect(() => {
    if (!player) {
      return;
    }

    player.on('timeupdate', updateTime);
    //player.on('progress', updateProgress);
    return () => {
      player.off('timeupdate', updateTime);
      //player.off('progress', updateProgress);
      };
    }, [active_player_id]);

  useEffect(() => {
    if (first_interaction && typeof first_interaction === 'number') {
      const now = Date.now();
      const diff = (now - first_interaction) / 1000;
      if (diff <= 5) {
        setVolume(100);
      }
    }
  }, [first_interaction]);
/*
  useEffect(() => {
    if (!player) {
      return;
    }
    console.log('sertting', volume)
    player.volume(volume);
  }, [volume]);
*/

  useEffect(() => {
    const check = P.isAnyPlayerInFullscreen();
    if (check) {
      P.closeFS();
    }
  }, [active_player_id]);

/*
  useEffect(() => {
    if (!player) {
      return;
    }
  
    async function checker() {
      while (true) {
        await timeout(1000);
        if (state && state.id) {
          if (state.id !== store.getState().root.active_player_id) {
            //console.log('true', state.id)
            player.pause();
          }
        }
      }
    }
    checker();
    
    if (active_player_id !== state.id) {
      player.pause();
    }
  }, [active_player_id, state.id]);
*/
  function timeout(ms: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, ms);
    })
  }

  function replaceLang(id: string, language: string) {
    const langs = ['en', 'ru'];
    if (langs.some(lang => id.includes(lang))) {
      if (id.includes(langs[0])) {
        return id.replace(langs[0], language);
      }
      return id.replace(langs[1], language);
    }
    return `${id}_${language}`;
  }

  useEffect(() => {
    if (player && existed_player_id) {
      const id = player.id();
      if (id === existed_player_id) {
        setActivePlayer(state.id);
        return;
      }
    }
  }, [existed_player_id]);
  const updateTime = () => {
    const state = store.getState();
 
    const id = player.getAttribute('data-id');
    if (id !== state.root.active_player_id) {
      //console.log('non-active player trying to update time');
      return;
    }

    const duration = player.duration() * 1000;
    const currentTime = player.currentTime() * 1000;

    const if_splitting_file_playing = PlayerController._isNormalOrSplittingSegmentPlaying(state.root.active_player_id);
    //@ts-ignore
    const current_position = PlayerController.calcCurrentPosition(state, currentTime);

    let updated_time = state.root.view_type !== 'piece' ?
    current_position
    :
    currentTime;
    if (state.root.transition_player) {
      return;
    }
    if (
      state.root.mode === 'normal' ||
      if_splitting_file_playing
      ) {
      updated_time = currentTime;
      //console.log('if_splitting_file_playing')
      
    }

    if (state.timeline.duration === 0) {
      return setTimeline({
        current_time: updated_time,
        current_segment_time: currentTime,
        duration: duration
      });
    }
 
    setCurrentTime({
      current_time: updated_time,
      current_segment_time: currentTime
    });
  }
/*
  useEffect(() => {
    if (active_player_id !== state.id) {
      return;
    }
    if (typeof started_at === 'number') {
      const timeout = setTimeout(() => {
        showControlBar({
          bottom: false,
          top: false
        });
        clearTimeout(timeout);
      }, 3000);
    }
  }, [started_at]);
*/
  return (
    <div
    style = {{
      width: '100%',
      height: '100%',
      position: 'absolute'
    }}
    >
      {
        renderVideo()
      }
    </div>
  );
};

export default connect((state: RootState) => ({
  active_player_id: state.root.active_player_id,
  registered_players: state.root.registered_players,
  mode: state.root.mode,
  view_type: state.root.view_type,
  duration: state.timeline.duration,
  quality_level: state.root.quality_level,
  reserved_player: state.root.reserved_player,
  pool: state.root.pool,
  watched: state.root.watched,
  found_segment: state.root.found_segment,
  rewinding: state.root.rewinding,
  next_default_segment: state.root.next_default_segment,
  prev_segment: state.root.prev_segment,
  scenario: state.root.scenario,
  isMobile: state.device.isMobile,
  device: state.device,
  language: state.root.language,
  subtitles: state.root.subtitles,
  transition_player: state.root.transition_player,
  first_interaction: state.root.first_interaction,
  volume: state.player.volume,
  paused: state.player.paused
}), {
  setDuration,
  setQualityLevel,
  setPaused,
  setScenario,
  setReservedPlayerState,
  setActivePlayer,
  setFoundSegment,
  setRewinding,
  setDisabledButtons,
  setShowBg,
  showControlBar,
  setVolume,
  setLanguage,
  showSubtitles,
  setTransitionPlayer,
  registerPlayer,
  unregisterPlayer,
  setCurrentTime,
  setTimeline
})(Player);