interface Props {
  onClick: any
  value?: any
  onMouseMove?: any
  onMouseLeave?: any
}

const Button = ({
  onClick,
  value
}: Props) => (
  <div
  onClick = { onClick }
  data-value = { value }
  data-id = { value }
  style = {{
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    cursor: 'pointer',
    zIndex: 9999
  }}
  ></div>
);

export default Button;