import {
  configureStore,
  Action
} from '@reduxjs/toolkit';
import {
  RootState,
} from '../types';
import reducer from './reducer';
import { ThunkAction } from 'redux-thunk'
import { MiddlewareArray } from '@reduxjs/toolkit';
import { customMiddleware, throttleMiddleware, logger } from './middleware';

const middlewares = [throttleMiddleware, customMiddleware];

const middleware = new MiddlewareArray().concat(...middlewares);

const store = configureStore({
  reducer,
  middleware
});

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>
export default store;