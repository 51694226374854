import View from './components/view';
import { Routes, Route } from 'react-router-dom';
import Example from './example_page';

const App = ({
  options,
  scenario
}) => {
  const shared_config = {
    options,
    scenario
  }
  const _src = 'http://download.tsi.telecom-paristech.fr/gpac/DASH_CONFORMANCE/TelecomParisTech/mp4-live-mux/mp4-live-mux-mpd-AV-BS.mpd';
  return (
    <Routes>
      <Route path = '/' element = { <View config = { {} } options = { shared_config.options } scenario = { shared_config.scenario } initial_language = { 'en' } /> } />
      <Route path = '/en' element = { <View config = { {} } options = { shared_config.options } scenario = { shared_config.scenario } initial_language = { 'en' } /> } />
      <Route path = '/ru' element = { <View config = { {} } options = { shared_config.options } scenario = { shared_config.scenario } initial_language = { 'ru' } /> } />
      <Route path = '/normal' element = { <Example/> } />
    </Routes>
  )
}

export default App;