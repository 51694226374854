import { Language, QualityLevel } from '../types';

const host_ = `https://d2ez8akk35wez5.cloudfront.net/out/INTRO_NI`;
const _host = `https://cdn.cdn-deep-movie.site/out/INTRO_NI`;

export const host = `https://cdn.player-standart-aws.deep.movie/out/INTRO_NI`;
export default function selectSource(language: Language, isSafari: boolean, quality: QualityLevel, captions?: boolean, isIos?: boolean) {
  //@ts-ignore
  const slow_internet_connection = window.slow_internet_connection;

  if (!quality || quality === 'auto') {
    quality = slow_internet_connection ? '480' : '1080';
  }

  const v_codec = isSafari ? 'hevc' : 'av1';
  const lang_string = language === 'ru' ? 'RUS' : 'ENG';

  if (captions) {
    return `${host}/${v_codec}/${language}/captions/INTRO_NI_${lang_string}.vtt`;
  }

  if (isIos) {
    return `${host}/hls/hevc/${language}/manifest.m3u8`;
  }

  return `${host}/${v_codec}/${language}/manifest.mpd`;
}