const Tooltip = ({
  text,
  direction
}) => {
  return (
    <div className = { `tooltip tooltip--${direction}`}>
      <span>
        {
          text
        }
      </span>
    </div>
  );
};

export default Tooltip;