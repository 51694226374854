
import { combineReducers } from '@reduxjs/toolkit';
import root from './root';
import timeline from './timeline';
import segment from './segment';
import player from './player';
import device from './device';

export default combineReducers({
  root,
  timeline,
  segment,
  player,
  device
});