import {
  TIMELINE, TimelineAction
} from '../actions';
import {
  TimelineState
} from '../../types';

const init_state: TimelineState = {
  duration: 0,
  current_time: 0,
  current_segment_time: 0
};

export default function Timeline(state = init_state, action: TimelineAction) {
  switch(action.type) {
    case TIMELINE.SET_SEKKED_TIME:
      return {
        ...state,
        seeked_time: action.payload
      };
    case TIMELINE.SET_DURATION:
      return {
        ...state,
        duration: Number(action.payload)
      };
    case TIMELINE.SET_CURRENT_TIME:
      return {
        ...state,
        current_time: Number(action.payload.current_time),
        current_segment_time: Number(action.payload.current_segment_time)
      };
    case TIMELINE.SET_TIMELINE:
      return {
        ...state,
        duration: Number(action.payload.duration),
        current_time: Number(action.payload.current_time),
        current_segment_time: Number(action.payload.current_segment_time)
      };
    default:
      return state;
  }
};