import CbButton from './cb';
import { ReactComponent as FSIcon } from '../../images/player/fullscreen.svg';
import { memo, useEffect, useState } from 'react';
import { Player } from '../../player';
import {
  fullscreen
} from '../../state/action-creators';
import { useAppDispatch } from '../../state/hooks';
import { VideoJsPlayer } from 'video.js';

export const CONTROLBAR_ID = 'cb-id';

function toggleFullscreen(player: VideoJsPlayer, element: HTMLElement, isIos: boolean, setFs?: any) {
  const content_el = player.contentEl();
  const parent = element.parentNode;
  content_el.appendChild(element);
  const timeout = setTimeout(() => {
    player.one('fullscreenchange', (e) => {
      console.log('fullscreenchange', e);
      parent.appendChild(element);
      if (typeof setFs === 'function' && isIos) {
        setFs(false);
      }
      clearTimeout(timeout);
    });
  }, 200);
}

const Fullscreen = ({
  active_player_id,
  isIos,
  vjs_player
}) => {
  const [fs, setFs] = useState(false);

  const onClick = () => {
    if (Player.isFullScreen()) {
      Player.fullscreen(false);
      setFs(false);
      return;
    }
    
    Player.fullscreen();
    setFs(true);
    const player_ = Player.getPlayer();
    const player = player_.player_;
    const cbs = [CONTROLBAR_ID, `${CONTROLBAR_ID}-top`];
    for (const cb of cbs) {
      const el = document.getElementById(cb);
      toggleFullscreen(player, el, isIos, setFs);
    }
  }
  useEffect(() => {
    if (!active_player_id) {
      return;
    }
    setFs(false);
  }, [active_player_id]);

  const onVJSClick = () => {
    if (vjs_player.isFullscreen()) {
      vjs_player.exitFullscreen();
      setFs(false);
      return;
    }

    vjs_player.requestFullscreen();
    setFs(true);
    const cbs = [CONTROLBAR_ID, `${CONTROLBAR_ID}-top`];
    for (const cb of cbs) {
      const el = document.getElementById(cb);
      toggleFullscreen(vjs_player, el, isIos, setFs);
    }
  } 

  return (
    <CbButton
    callback = { vjs_player ? onVJSClick : onClick }
    >
      <span className = { !fs ? 'u-fs' : 'u-fs-out' }></span>
    </CbButton>
  )
}

export default memo(Fullscreen);