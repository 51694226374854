import segment from './state/reducer/segment';
import { Segment } from './types';

const scenario: Segment[] = [
  {
    id: '1D',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_1D_4k.m3u8',
    linked_with: [
      '2D_3D',
      //'2F_3D_3F'
    ],
    duration: 29,
    subtitles: '',
    default: true,
    type: 'intro',
    role: 'scenario-scene',
    branch: {
      id: 'bDrf1y',
      role: 'beggining'
    }
  },
  {
    id: '2D_3D',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_2D_3D_4k.m3u8',
    linked_with: [
      '9D_C',
      '4D_S',
      '7D_WL',
      '6D_A'
    ],
    duration: 23,
    subtitles: '',
    default: true,
    type: 'interactive',
    role: 'scenario-scene',
    branch: {
      id: 'bDrf1y',
      role: 'end'
    }
  },
  {
    id: '2F_3D_3F',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_2F_3D_3F_4k.m3u8',
    linked_with: [
      '9D_C',
      '4D_S',
      '7D_WL',
      '6D_A'
    ],
    duration: 33,
    subtitles: '',
    default: true,
    type: 'interactive',
    role: 'scenario-scene',
    branch: {
      id: 'bDrf1y',
      role: 'end'
    }
  },
  {
    id: '4D_S',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_4D_S_4k.m3u8',
    linked_with: [
      '4F_S',
    ],
    duration: 15,
    subtitles: '',
    default: false,
    type: 'interactive',
    role: 'scenario-scene',
    branch: {
      id: 'IeWFmh',
      role: 'all'
    }
  },
  {
    id: '4F_S',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_4F_S_4k.m3u8',
    linked_with: [
      '9D_C',
      '7D_WL',
      '6D_A'
    ],
    duration: 15,
    subtitles: '',
    default: false,
    type: 'interactive',
    role: 'scenario-scene',
    branch: {
      id: 'suTysn',
      role: 'all'
    }
  },
  {
    id: '6D_A',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_6D_A_4k.m3u8',
    linked_with: [
      '10D',
    ],
    duration: 12,
    subtitles: '',
    default: false,
    role: 'scenario-scene',
    branch: {
      id: 's2SJE0',
      role: 'beggining'
    }
  },
  {
    id: '7D_WL',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_7D_WL_4k.m3u8',
    linked_with: [
      '9D_C',
      '4D_S',
      '7D_WL',
      '6D_A'
    ],
    duration: 17,
    subtitles: '',
    default: false,
    type: 'interactive',
    role: 'scenario-scene',
    branch: {
      id: 'XuTP3O',
      role: 'all'
    }
  },
  {
    id: '9D_C',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_9D_C_4k.m3u8',
    linked_with: [
      '10D'
    ],
    duration: 12,
    subtitles: '',
    default: true,
    role: 'scenario-scene',
    branch: {
      id: 'DaXQ2x',
      role: 'beggining'
    }
  },
  {
    id: '10D',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_10D_4k.m3u8',
    linked_with: [
      '16D',
      '11D'
    ],
    duration: 35,
    subtitles: '',
    default: true,
    type: 'interactive',
    role: 'scenario-scene',
    branch: {
      id: ['IeWFmh', 'DaXQ2x', 'XuTP3O', 's2SJE0'],
      role: 'end'
    }
  },
  {
    id: '11D',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_11D_4k.m3u8',
    linked_with: [
      '12F',
      '13F',
      '14,2D_SV',
    ],
    duration: 46,
    subtitles: '',
    default: true,
    role: 'scenario-scene',
    branch: {
      id: 'vGDIES',
      role: 'all'
    }
  },
  {
    id: '12F',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_12F_4k.m3u8',
    linked_with: [
      '14,2D_VF'
    ],
    duration: 6,
    subtitles: '',
    default: false,
    role: 'scenario-scene',
    branch: {
      id: 'yMyCrd',
      role: 'beggining'
    }
  },
  {
    id: '13F',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_13F_4k.m3u8',
    linked_with: [
      '14,2D_VF'
    ],
    duration: 35,
    subtitles: '',
    default: false,
    role: 'scenario-scene',
    branch: {
      id: 'Sf4bSQ',
      role: 'beggining'
    }
  },
  {
    id: '14,2D_SV',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_14_2D_SV_4k.m3u8',
    linked_with: [
      '14,2D_VF'
    ],
    duration: 66,
    subtitles: '',
    default: true,
    branch: {
      id: 'jmnBG5',
      role: 'beggining'
    }
  },
  {
    id: '14,2D_VF',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_14_2D_FV_4k.m3u8',
    linked_with: [
      '10D',
      '15F'
    ],
    duration: 10,
    subtitles: '',
    default: true,
    branch: {
      id: ['jmnBG5', 'Sf4bSQ', 'yMyCrd'],
      role: 'end'
    }
  },
  {
    id: '15F',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_15F_4k.m3u8',
    linked_with: [
      '17D_P1',
      '17D_P2'
    ],
    duration: 54,
    subtitles: '',
    default: true,
    role: 'scenario-scene',
    branch: {
      id: 'Lk4bHU',
      role: 'beggining'
    }
  },
  {
    id: '16D',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_16D_4k.m3u8',
    linked_with: [
      '17D_P1',
      '17D_P2'
    ],
    duration: 40,
    subtitles: '',
    default: true,
    type: 'interactive',
    role: 'scenario-scene',
    branch: {
      id: 'jFAsIY',
      role: 'beggining'
    }
  },
  {
    id: '17D_P1',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_17D_P1_4k.m3u8',
    linked_with: [
      '17D_P2'
    ],
    duration: 104,
    subtitles: '',
    default: true,
    type: 'parallel',
    role: 'scenario-scene',
    parallel_with: '17D_P2',
    branch: {
      id: ['jFAsIY', 'Lk4bHU'],
      role: 'end'
    }
  },
  {
    id: '17D_P2',
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/40/INTRO_17D_P2_4k.m3u8',
    linked_with: [
      '17D_P1',
    ],
    duration: 104,
    subtitles: '',
    default: true,
    type: 'parallel',
    role: 'scenario-scene',
    parallel_with: '17D_P1',
    branch: {
      id: ['jFAsIY', 'Lk4bHU'],
      role: 'end'
    }
  },
  {
    id: 'INTRO_SF_IMM1',
    duration: 5,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_IMM1_1080.m3u8',
    role: 'splitting'
  },
  {
    id: 'INTRO_SF_IMM2',
    duration: 7,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_IMM2_1080.m3u8',
    role: 'splitting'
  },
  {
    id: 'INTRO_SF_NORM1',
    duration: 9,
    subtitles: '',
    linked_with: [
      'NI'
    ],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_NORM1_1080.m3u8',
    role: 'splitting'
  },
  {
    id: 'INTRO_SF_NORM2',
    duration: 4,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_NORM2_1080.m3u8',
    role: 'splitting'

  },
  {
    id: 'INTRO_SF_NORM3',
    duration: 6,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_NORM3_1080.m3u8',
    role: 'splitting'
  },
  {
    id: 'NI',
    duration: 172,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/INTRO_NI_4k.m3u8'
  },
];

const modified_scenario = [];

const levels = {
  '480': 'video_1000000',
  '360': 'video_750000',
  '720': 'video_2300000',
  '1080': 'video_4300000',
  '2160': 'video_51000000',
  'auto': ''
}

for (const _segment of scenario) {
  const langs = ['en', 'ru'];
  for (const lang of langs) {
    modified_scenario.push(
      {
        ..._segment,
        id: `S_${_segment.id}_${lang}`,
        linked_with: _segment.linked_with.map(seg => `S_${seg}_${lang}`),
        branch: _segment.branch ? _segment.branch : {
          id: 'testing' + _segment.id,
          role: 'all'
        }
      }
    );
    for (const level of Object.keys(levels)) {
      const segment = {
        ..._segment,
        id: `S_${_segment.id}_${lang}_${level}`
      }
      modified_scenario.push(segment);
    }
  }
}

export default modified_scenario;