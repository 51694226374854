import usePlayer from './components/use-player';
import DeviceDetector from 'device-detector-js';
import { useEffect, useRef, useState } from 'react';
import useWindowSize from './components/use-window-resize';
import Controlbar from './components/controlbar';
import ControlbarTop from './components/controlbar-top';
import LoadingSpinner from './components/loading-spinner';
import { setActivePlayer, setTimeline, setCurrentTime, setPaused, showControlBar, firstInteraction,
  isMobile as setIsMobile
} from './state/action-creators';
import { useDispatch } from 'react-redux';
import { useAppSelector } from './state/hooks';

const default_state = {
  id: '',
  label: 'test',
  segment: {
    id: '',
    duration: 0,
    subtitles: '',
    default: false,
    linked_with: [],
    src: '',
    dropped_on: 0
  }
};

const deviceDetector = new DeviceDetector();

const Example = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const view_ref = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  });

  const info = deviceDetector.parse(window.navigator.userAgent);
  const device = info.device;
  const isSafari = info.client.name.toLowerCase() === 'safari' ||
    info.client.name.toLowerCase().includes('safari');
  const type = info.device.type.slice();
  const isIos = info && info.os.name.toLowerCase() === 'ios';
  const isMobileDevice = () =>
    type.toLowerCase().includes('smartphone') ||
    type.toLowerCase().includes('mobile') ||
    type.toLowerCase().includes('phone') ||
    info.os.name.toLowerCase().includes('ios') ||
    info.os.name.toLowerCase().includes('android');
  
  const isMobile = isMobileDevice();
  const {
    renderVideo,
    ready,
    player
  } = usePlayer({
    player_state: default_state,
    isMobile,
    isSafari,
    isIos,
    mode: 'another'
  });

  const rewinding = useAppSelector(state => state.root.rewinding);
  const first_interaction = useAppSelector(state => state.root.first_interaction);
  const quality_level = useAppSelector(state => state.root.quality_level);
  const volume = useAppSelector(state => state.player.volume);
/*
  useEffect(() => {
    if (!player) {
      return;
    }
    if (typeof volume === 'number') {
      player.volume(volume);
    }
  }, [ready, volume]);
*/
  useEffect(() => {
    if (!player) {
      return;
    }
    //@ts-ignore
    const ultra_condition = player.tech && player.tech(false).vhs && typeof player.tech(false).vhs.representations === 'function';
    if (!ultra_condition) {
      return;
    }
    //@ts-ignore
    const list = player.tech(false).vhs.representations();
    for (const rep of list) {
      rep.enabled(rep.height === parseInt(quality_level));
    }
  }, [quality_level]);

  useEffect(() => {
    document.addEventListener('click', detectFirstInteraction, true);
  }, []);
  function detectFirstInteraction() {
    dispatch(firstInteraction(Date.now()));
    document.removeEventListener('click', detectFirstInteraction, true);
  }

  useEffect(() => {
    if (rewinding && rewinding.actual) {
      player.currentTime(rewinding.seconds / 1000);
    }
  }, [rewinding]);

  const _src = 'https://dash.akamaized.net/akamai/bbb_30fps/bbb_30fps.mpd';

  const handlePlayerState = (event) => {
    if (!player || !event) {
      return;
    }

    if (event.type === 'pause') {
      //console.log('puse: ', event);
      dispatch(setPaused(true));
    }
    if (event.type === 'play') {
      dispatch(setPaused(false));
    }
  };

  useEffect(() => {
    if (ready) {
      player.src({
        src: _src,
        type: 'application/dash+xml'
      });
      dispatch(setActivePlayer('another'));
      const is_mobile = isMobileDevice();
      dispatch(setIsMobile(is_mobile));
      player.on('loadedmetadata', (e) => {
        const c = player.currentTime() * 1000;
        const d = player.duration() * 1000;
        dispatch(setTimeline({
          current_segment_time: c,
          current_time: c,
          duration: d
        }));
      });
      player.on('timeupdate', (e) => {
        const time = player.currentTime() * 1000;
        dispatch(setCurrentTime({
          current_time: time,
          current_segment_time: time
        }));
      });
      
      player.on('pause', handlePlayerState);
      player.on('play', handlePlayerState);
    }
  }, [ready]);

  
  useEffect(() => {
    const box = view_ref.current;
    if (box) {
      const parent = box.parentNode;
      //@ts-ignore
      const p_w = parent.offsetWidth;
      const p_h = parent.offsetHeight;
      const d = matchAspectRatio({ height: p_h, width: p_w }, [16, 9]);
      console.log('ph', p_h, p_w)
      console.log('d', d);
      setDimensions({
        ...d
      });
    }
  }, [view_ref.current, width]);
  
  function matchAspectRatio(dimensions: { width: number, height: number }, ratio: number[]): { width: number, height: number } {
    const { width, height } = dimensions;
    const viewport_height = height;
    const perfect_height = width / ratio[0] * ratio[1];

    if (viewport_height > perfect_height) {
      return {
        height: perfect_height,
        width: perfect_height / ratio[1] * ratio[0]
      };
    }
    return {
      width: viewport_height / ratio[1] * ratio[0],
      height: viewport_height
    };
  }

  const renderControls = () => {
    const controls = [Controlbar, ControlbarTop, LoadingSpinner];
    return controls.map((C, i) => {
      //@ts-ignore
      return <C vjs_player = { player }  id = { 'any' } key = { `control_${i}` }  />;
    });
  }

  return (
    <div
    style = {{
      width: '100%',
      height: '100vh',
      position: 'relative',
      marginTop: isMobile ? '30%' : 0,
    }}
    >
      <div
      //onMouseMove = { (first_interaction && !isMobile) ? onMouseMove : () => {} }
      ref = { view_ref }
      id = { 'box' }
      style = {{
        position: 'relative',
        overflow: 'hidden',
        maxHeight: '100vh',
        ...dimensions
      }}
      >
        <div
        
          style = {{
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
          >
            {
              renderVideo()
            }
        </div>
        {
          ready && renderControls()
        }
      </div>
    </div>
  );
}

export default Example;