import MenuBtn from '../menu-btn';
import { memo } from 'react';
import { Menus } from '../controlbar';
import { ShowSubtitlesPayload } from '../../state/actions';

const SubsIcon = () => <span className = 'icon icon-language' >CC</span>

interface ISubsBtn {
  subtitles: ShowSubtitlesPayload
  showSubtitles: any
  openedMenu: Menus
  setOpenedMenu: Function
  menu_id: Menus
  show_bottom_controlbar: boolean
  isMobile?: boolean
  isSubMenu?: boolean
}

const SubsBtn = ({
  subtitles,
  showSubtitles,
  openedMenu,
  setOpenedMenu,
  menu_id,
  show_bottom_controlbar,
  isMobile,
  isSubMenu
}: ISubsBtn) => {
  const _m = ['English', 'Russian', 'off'];
  const items = _m.map(item => ({
    id: item,
    value: item,
    label: item,
    checkbox: {
      //@ts-ignore
      checked: (item !== 'off' ? item.toLowerCase().includes(subtitles.language) && subtitles.show : (!subtitles.show)) 
    }, 
    //@ts-ignore
    selected: (item !== 'off' ? item.toLowerCase().includes(subtitles.language) && subtitles.show : (!subtitles.show)),
    cb: (e) => {
      const id = e.target.getAttribute('data-id');
      if (!id) {
        return;
      }
      if (id === 'off') {
        showSubtitles({
          show: false,
          language: 'en'
        });
        return;
      }
      let [language] = id.split('_');
      language = language.toLowerCase().slice(0, 2);
      //console.log('language', language, id);
      showSubtitles({
        show: true,
        language
      });
    }
  }));

  return (
    <MenuBtn
    selected = { subtitles.language }
    label = { 'Subtitles menu' }
    type = { 'simple' }
    items = { items }
    Icon = { SubsIcon }
    openedMenu = { openedMenu }
    setOpenedMenu = { setOpenedMenu }
    id = { menu_id }
    show_bottom_controlbar = { show_bottom_controlbar }
    isMobile = { isMobile }
    isSubMenu = { isSubMenu }
    show_subs = { subtitles.show }
    />
  )
}

export default SubsBtn;