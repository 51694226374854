import React, { FC, memo, useEffect, useState } from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { SliderRail, Handle, Track, RightTrack } from './slider-components';
import store from '../state/store';
import PlayerController from '../player/controller';
import ControlbarBtn from './controlbar-btn';
import Checkbox from './checkbox';
import VeilBtn from './buttons/veil';
import getSeekableSegment from '../utils/get-seekable-segment';
import {
  TimelineState,
  ViewType,
  RewindingState,
  Language
} from '../types';
import { VideoJsPlayer } from 'video.js';
import { Player } from '../player';
import { useAppDispatch } from '../state/hooks';

const sliderStyle = {
  position: 'relative',
  //width: '90%',
  flex: 1,
  height: '100%',
  //minHeight: '20px'
  //touchAction: 'none',
};

function ViewTypeSelector ({
  text,
  options,
  selected,
  onChange
}) {
  const handleOnChange = (e) => {
    onChange(e.target.getAttribute('data-value'));
  }
  return (
    <div
    className = 'vt-selector'>
      <span>
        {
          text
        }
      </span>
      <div className = 'vt-selector-options'>
      {
        options.map(opt => (
          <div
          key = { opt.value }
          className = 'vt-selector-option'>
            <Checkbox
            checked = { opt.value === selected }
            />
            <span>
              {
                opt.name
              }
            </span>
            <VeilBtn
            value = { opt.value }
            onClick = { handleOnChange }
            />
          </div>
        ))
      }
      </div>
    </div>
  );
};

const content = {
  ru: {
    vt: 'Выберите, контролировать весь фильм или только текущий сегмент'
  },
  en: {
    vt: 'Choose to control the entire movie or current story piece'
  }
}

interface IProps {
  timeline: TimelineState
  setCurrentTime: any
  setTimeline: any
  active_player_id: string
  view_type: ViewType
  setViewType: any
  rewinding: RewindingState
  setRewinding: any
  language: Language
  isMobile: boolean
  setOpenedMenu?: any
  openedMenu?: string
  menu_id?: string
  show_bottom_controlbar?: boolean
  vjs_player?: VideoJsPlayer
}

const CustomSlider = ({
  timeline,
  setCurrentTime,
  setTimeline,
  active_player_id,
  view_type,
  setViewType,
  rewinding,
  setRewinding,
  language,
  isMobile,
  setOpenedMenu,
  openedMenu,
  menu_id,
  vjs_player,
  show_bottom_controlbar
}: IProps) => {
  const [localRewinding, setLocalRewinding] = useState(false);
  const [progress, setProgress] = useState(0);
  const [seekable, setSeekable] = useState(0);
  const [position, setPosition] = useState({
    x: 0,
    y: 0
  });

  const [trackPosition, setTrackPosition] = useState(0);
  const dispatch = useAppDispatch();

  const onChange = (values: number[]) => {
    //console.log('<---- onChange ', values[0]);
  };

  useEffect(() => {
    const _player = vjs_player || Player.getPlayer();
    if (!_player) {
      return;
    }
    const player = _player.player_;
    if (!player) {
      return;
    }
    //player.on('timeupdate', updateTime);
    player.on('progress', updateProgress);
    return () => {
      //player.off('timeupdate', updateTime);
      player.off('progress', updateProgress);
    };
  }, [active_player_id]);

  useEffect(() => {
    const _player = vjs_player || Player.getPlayer();
    const player = _player.player_;
    if (!player) {
      return;
    }
    const id = player.getAttribute('data-id');

    if (id !== active_player_id && !vjs_player) {
      return;
    }
    const time = Number(timeline.current_time.toFixed(0));

    if (timeline && timeline.current_time !== undefined) {
      if (localRewinding) {
        setTimeout(() => {
          setLocalRewinding(false);
        }, 100);
        return;
      }
      setTrackPosition(time);
    }
    
    if (view_type === 'piece') {
      return;
    }

    if (rewinding && rewinding.actual) {
      setTrackPosition(rewinding.seconds);
      return;
    }

  }, [timeline.current_time, rewinding.actual]);

  const updateProgress = () => {
  
    const _player = vjs_player || Player.getPlayer();
    if (!_player) {
      return;
    }

    const player = _player.player_;
    if (!player) {
      return;
    }
    const state = store.getState().root;
    const id = player.getAttribute('data-id');
    const buffered = Math.floor(player.bufferedEnd());

    if (id !== state.active_player_id) {
      return;
    }
    if (buffered - progress < 1) {
      return;
    }
    setProgress(buffered * 1000);
  }

  const updateTime = () => {
    const _player = Player.getPlayer();
    if (!_player) {
      return;
    }
    
    const player = _player.player_;
 
    const state = store.getState();
 
    const id = player.getAttribute('data-id');
    if (id !== state.root.active_player_id) {
      //console.log('non-active player trying to update time');
      return;
    }

    const duration = player.duration() * 1000;
    const currentTime = player.currentTime() * 1000;

    const if_splitting_file_playing = PlayerController._isNormalOrSplittingSegmentPlaying(state.root.active_player_id);
    //@ts-ignore
    const current_position = PlayerController.calcCurrentPosition(state, currentTime);

    let updated_time = state.root.view_type !== 'piece' ?
    current_position
    :
    currentTime;
    if (state.root.transition_player) {
      return;
    }
    if (
      state.root.mode === 'normal' ||
      if_splitting_file_playing
      ) {
      updated_time = currentTime;
      //console.log('if_splitting_file_playing')
      
    }

    if (state.timeline.duration === 0) {
      return dispatch(setTimeline({
        current_time: updated_time,
        current_segment_time: currentTime,
        duration: duration
      }))
    }
 
    dispatch(setCurrentTime({
      current_time: updated_time,
      current_segment_time: currentTime
    }));
  }

  useEffect(() => {
    const _player = Player.getPlayer();
    if (!_player) {
      return;
    }
    const player = _player.player_;
    if (!player) {
      return;
    }
    if (player.getAttribute('data-id') !== active_player_id) {
      return;
    }

    const state = store.getState();

    const current_segment_time = player.currentTime() * 1000;
    const is_splitting_file_playing = PlayerController.isSplittingFilePlaying(active_player_id);
    
    if (view_type === 'movie' && !is_splitting_file_playing && !state.root.transition_player) {
      //@ts-ignore
      const new_duration = PlayerController.calcTimeline(state.root);
      //@ts-ignore
      let new_current_time = PlayerController.calcCurrentPosition(state, current_segment_time);
      dispatch(setTimeline({
        duration: new_duration,
        current_time: new_current_time,
        current_segment_time
      }));
    }
    if (view_type === 'piece' || PlayerController.isNormalSegmentPlaying(active_player_id) || is_splitting_file_playing) {
      const dur = player.duration() * 1000;
      const curr = current_segment_time;
      const segment_duration = PlayerController.findSegmentById(active_player_id, state.root.scenario).duration
      
      dispatch(setTimeline({
        duration: segment_duration,
        current_time: curr && curr !== dur ? curr : 0,
        current_segment_time
      }));
    }
  }, [view_type, active_player_id]);
  
  useEffect(() => {
    const _player = Player.getPlayer();
    if (!_player) {
      return;
    }
    const player = _player.player_;
    if (!player) {
      return;
    }
    if (player.getAttribute('data-id') !== active_player_id) {
      return;
    }
    if (timeline.seeked_time !== undefined) {
      //console.log('called timeline.seeked_time', timeline.seeked_time);
      player.currentTime(timeline.seeked_time / 1000);
     
      dispatch(setRewinding({
        actual: false,
        seconds: 0
      }));     
    }
  }, [timeline.seeked_time]);

  const formatSecs = (m_secs: number) => {
    const secs = Number((m_secs / 1000).toFixed(0));
    if (secs < 60) return `0:${secs < 10 ? `0${secs}` : secs.toFixed()}`;

    const minutes = Math.floor(secs / 60);
    const remaining = Math.floor(secs - minutes * 60);
    return `${minutes}:${remaining < 10 ? `0${remaining}` : remaining}`;
  }

  const findPositionRelativeToElement = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left; //x position within the element.
    const y = e.clientY - rect.top;  //y position within the element.
    return {
      x,
      y
    };
  }

  const domain = [0, timeline.duration ? (timeline.duration) : 20];

  const onUpdate = ({values}) => {
    //console.log('<---- onUpdate', values);
  }

  const calcBufferedProgress = () => {
    const is_splitting_or_normal_segment = PlayerController
      ._isNormalOrSplittingSegmentPlaying(active_player_id);
    if (
      view_type === 'piece' ||
      is_splitting_or_normal_segment
      ) {
        return progress / timeline.duration * 100;
      }
    const previous_duration = PlayerController.getPreviousDuration();
    const progress_percet = (previous_duration + progress) / timeline.duration * 100;

    return progress_percet >= 100 ? 100 : progress_percet;
  };

  const handleOnMouseMove = (e: any) => {
    const { x, y } = findPositionRelativeToElement(e);
    setPosition({
      x,
      y
    });
    const sliders = document.getElementsByClassName('slider-wrapper');
    for (let i = 0; i < sliders.length; i ++) {
      const slider = sliders[i];
      //@ts-ignore
      slider.style.setProperty('--x', x + 'px');
    }    

    const width = e.target.offsetWidth;
    setSeekable(getSeekableSegment(
    	Number(x.toFixed(0)),
    	Number(width.toFixed(0)),
    	timeline.duration
    	));
  };

  const seeking = (e) => {
    const { x } = findPositionRelativeToElement(e);
    const width = e.target.offsetWidth;
    setLocalRewinding(true);
    if (view_type === 'piece') {
      
    }
    const seconds = getSeekableSegment(x, width, timeline.duration);

    setTrackPosition(seconds);

    dispatch(setRewinding({
      actual: true,
      seconds: seconds
    }));
  }

  return (
    <>
      <div
      className = 'custom-controlbar'>
        {
          <ControlbarBtn
          arrow = { true }
          direction = { 'bottom-right' }
          isMobile = { isMobile }
          no_bg = { true }
          openedMenu = { openedMenu }
          menu_id = { menu_id }
          setOpenedMenu = { setOpenedMenu }
          show_bottom_controlbar = { show_bottom_controlbar }
          label = {{
            name: view_type
          }}
          tooltipText = { content[language].vt }
          classes = { [isMobile ? 'u-wm' : 'u-wu', 'u-pl-tv'] }
          >
            <ViewTypeSelector
            text = { content[language].vt }
            onChange = { setViewType }
            options = {[
              {
                name: 'Movie',
                value: 'movie'
              },
              {
                name: 'Piece',
                value: 'piece'
              }
            ]}
            selected = { view_type }
            />
          </ControlbarBtn>
        }
        <Slider
          step={250}
          domain={domain}
          rootStyle={sliderStyle}
          onChange={onChange}
          onUpdate = { onUpdate }
          disabled = { false }
          values={ [trackPosition] }
        >
          <Rail
          >
            {({ getRailProps }) =>
            <SliderRail
            getRailProps={getRailProps}
            />
            }
          </Rail>

          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    //@ts-ignore
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <React.Fragment
                  //@ts-ignore
                  key = { id }
                  >
                  <Track
                   key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                  <RightTrack
                  key = { id + 'right' }
                  source = {source}
                  target = {{
                    percent: calcBufferedProgress()
                  }}
                  getTrackProps={getTrackProps}
                  />
                  </React.Fragment>
                ))}
              </div>
            )}
          </Tracks>
        <div
        className = 'time-display'
        > 
          <span>
            {
              timeline && timeline.duration ?
              `${formatSecs(timeline.current_time)} / ${formatSecs(timeline.duration)}` : `0:00 / 0:00`
            }
          </span>
        </div>

        <div
        onMouseMove = { handleOnMouseMove }
        onClick = { seeking }
        className = 'slider-wrapper'
        id = 'slider-wrapper'
        >
        </div>
        </Slider>
      </div>
      
      </>
    );
  
}

export default memo(CustomSlider);