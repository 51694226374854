import * as React from 'react';

const TRACKS_HEIGHT = '100%';

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
  position: 'absolute',
  width: '100%',
  top: 0,
  height: TRACKS_HEIGHT,
  //transform: 'translate(0%, -50%)',
  //borderRadius: 7,
  cursor: 'pointer',
  backgroundColor: '#a0a0a0'
};


const railInnerStyle = {
  position: 'absolute',
  width: '100%',
  height: 14,
  transform: 'translate(0%, -50%)',
  //borderRadius: 7,
  pointerEvents: 'none',
  backgroundColor: 'rgb(155,155,155)',
};


export const SliderRail = ({ getRailProps }) => {
  return (
    <>
      <div className = 'rail' style={railOuterStyle} {...getRailProps()} />
      { /**@ts-ignore */ }
    </>
  );
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
/*
interface HandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  disabled?: boolean;
}
interface SliderRailProps {
  getRailProps: GetRailProps;
}
*/
export const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
}) => {
  return (
    <>
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 5,
          width: 18,
          height: TRACKS_HEIGHT,
          cursor: 'pointer',
          backgroundColor: 'none',
          opacity: 0,
        }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
          width: 14,
          height: TRACKS_HEIGHT,
          //borderRadius: '50%',
          boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
          backgroundColor: disabled ? '#666' : '#9BBFD4',
          opacity: 0,
        }}
      />
    </>
  );
};

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export const KeyboardHandle = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
}) => {
  return (
    <button
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
        width: 24,
        height: 24,
        //borderRadius: '50%',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
        backgroundColor: disabled ? '#666' : '#9BBFD4',
      }}
      {...getHandleProps(id)}
    />
  );
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
/*
interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled?: boolean;
}
*/
export const Track = ({
  source,
  target,
  getTrackProps,
  disabled = false,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        //transform: 'translate(0%, -50%)',
        height: TRACKS_HEIGHT,
        zIndex: 2,
        background: disabled ? '#999' : '#cfcfcf',
        //borderRadius: 7,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
};
export const RightTrack = ({
  source,
  target,
  getTrackProps,
  disabled = false,
}) => {
  //console.log('recieved buffered time: ', target.percent);
  return (
    <div
      style={{
        position: 'absolute',
        //transform: 'translate(0%, -50%)',
        height: TRACKS_HEIGHT,
        zIndex: 1,
        overflow: 'hidden',
        backgroundColor: disabled ? '#999' : 'hsla(0,0%,100%,.25)',
        //borderRadius: 7,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent >= 100 ? 100 : target.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
/*
interface TickProps {
  tick: SliderItem;
  count: number;
  format?: (val: number) => string;
}
*/

export const Tick = ({
  tick,
  count,
  format = (d) => d,
}) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: 14,
          width: 1,
          height: 5,
          backgroundColor: 'rgb(200,200,200)',
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: 22,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
};

const { Component, Fragment } = React;

// *******************************************************
// TOOLTIP RAIL
// *******************************************************
const railStyle: React.CSSProperties = {
  position: "absolute",
  width: "100%",
  height: TRACKS_HEIGHT,
  backgroundColor: 'transparent',
  //top: -13,
  //borderRadius: 7,
  cursor: "pointer",
  //opacity: 0.3,
  zIndex: 302,
  //border: "1px solid grey"
};

const railCenterStyle: React.CSSProperties = {
  position: "absolute",
  width: "100%",
  height: TRACKS_HEIGHT,
  //borderRadius: 7,
  cursor: "pointer",
  pointerEvents: "none",
  backgroundColor: "#a0a0a0"
};

interface TooltipRailProps {
  activeHandleID: string;
  getRailProps: (props: object) => object;
  getEventData: (e: Event) => object;
  setSeekable: any
  onMouseMove?: any
  onClick?: any
}

export class TooltipRail extends Component<TooltipRailProps> {
  state = {
    value: null,
    percent: null
  };

  static defaultProps = {
    disabled: false
  };

  onMouseEnter = () => {
    document.addEventListener("mousemove", this.onMouseMove);
  };

  onMouseLeave = () => {
    this.setState({ value: null, percent: null });
    document.removeEventListener("mousemove", this.onMouseMove);
  };

  onMouseMove = (e: Event) => {
    const { activeHandleID, getEventData, setSeekable } = this.props;

    if (activeHandleID) {
      this.setState({ value: null, percent: null });
    } else {
      this.setState(getEventData(e));
      setSeekable(this.state.value);
    }
  };

  render() {
    const { value, percent } = this.state;
    const { activeHandleID, getRailProps, onMouseMove, onClick } = this.props;

    return (
      <Fragment>
        {!activeHandleID && value ? (
          <div
          >
            
          </div>
        ) : null}
        <div
          onClick = { onClick }
          onMouseMove = { onMouseMove }
          style={railStyle}
          {...getRailProps({
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave
          })}
        />
        <div style={railCenterStyle} />
      </Fragment>
    );
  }
}