import React from 'react';

const content = {
  message: {
    en: "Sorry! Your browser is not currently supported. Please choose one of the browsers below to view the video.",
    ru: "Извините! В данный момент ваш браузер не поддерживается. Пожалуйста, выберите один из браузеров, указанных ниже для просмотра видео."
  },
  supported_browsers: [
    {
      name: 'Chrome',
      icon: '/icons/chrome.png'
    },
    {
      name: 'Firefox',
      icon: '/icons/firefox.png'
    },
    {
      name: 'Safari',
      icon: '/icons/safari.png'
    },
    {
      name: 'Yandex',
      icon: '/icons/yb.png',
    },
    {
      name: 'Opera',
      icon: '/icons/opera.png'
    }
  ]
}

const NotSupported = () => (
  <div className = 'message_wrapper'>
    <div className = 'message_not-supported'>
      {
        ['en'].map(lang => (
          <div
          key = { lang }
          className = 'message_container'>
            <div className = 'message'>
              <h1>
                {
                  content.message[lang]
                }
              </h1>
            </div>
          </div>
        ))
      }
    <div className = 'message_additional-info'>
      {
        content.supported_browsers.map(item => (
          <div
          key = { item.name + item.icon }
          className = { 'message_additional-info_item' }
          >
            <img
            style = {{
              width: '3.5rem',
              height: '3.5rem'
            }}
            alt = { `${item.name} icon` }
            src = { item.icon }
            />
            <span>
              {
                item.name
              }
            </span>
          </div>
        ))
      }
    </div>
    </div>
  </div>
)

export default NotSupported;