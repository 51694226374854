import { Language } from '../types';

export default function(lanugage: Language, quality: string) {
  const host = 'https://cdn.cdn-deep-movie.site/out/INTRO_NI/hls/hevc';

  const levels = {
    '480': 'video_1000000',
    '360': 'video_750000',
    '720': 'video_2300000',
    '1080': 'video_4300000',
    '2160': 'video_51000000'
  }

  return `${host}/${lanugage}/${levels[quality]}.m3u8`;
}