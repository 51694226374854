import { Branch, Segment, BranchItemRole } from '../types';
import { SPLITTING_FILES } from './manage-splitting-files';

class BranchManager {
  current_branch: Branch;
  current_segment: Segment;
  previous_branches: Branch[] = [];
  scenario: Segment[];
  mode: 'silent' | 'prod';
  constructor(scenario: Segment[], mode?: 'silent' | 'prod') {
    this.scenario = scenario;
    if (mode && ['silent', 'prod'].includes(mode)) {
      this.mode = mode;
    }
  }
  init({
    current_segment,
  }: {
    current_segment: Segment,
  }) {
    this.current_branch = {
      beggining: current_segment,
      middle: undefined,
      end: this._findBranchItem(current_segment.branch.id as string, 'end')
    };
    this.current_segment = current_segment;
  }
  
  getNextSeekableSegment(seconds: number): Segment {
    const previous_duration = this.getDurationOfPreviousBranchesWithCurrentPosition();
    if (
      this._isSegmentTheBegginingOfTheBranch(this.current_segment) ||
      this._isSegmentTheEndOfTheBranch(this.current_segment)
    ) {
      const dropped_on = seconds - (previous_duration + this.current_segment.duration);
      return {
        ...this.current_branch.end,
        dropped_on
      };
    }
  }

  getPreviousSeekableSegment(seconds: number): Segment {
    const previous_duration = this.getDurationOfPreviousBranchesWithCurrentPosition();
    if (previous_duration < seconds) {
      throw 'Provided seconds not refer to the previous segments';
    }
    let overal_duration = 0;
    let checked_branches = [];
    this.showState();

    let branches_to_explore = this.previous_branches.length > 0 ?
      [...this.previous_branches, this.current_branch] : [this.current_branch]

    for (let branch of branches_to_explore) {

      overal_duration += branch.beggining.duration;
      checked_branches.push(branch.beggining.duration);
      if (
        overal_duration >= seconds
      ) {
        const segment = this._copyObject(branch.beggining);
        segment.dropped_on = seconds - checked_branches.slice(0, checked_branches.length - 1)
          .reduce((acc, val) => acc + val, 0);;
        return segment;
      }

      if (this._isSegmentAutonomous(branch.beggining)) {
        continue;
      }

      overal_duration += branch.end.duration;
      checked_branches.push(branch.end.duration);

      if (
        overal_duration >= seconds
        ) {
          const segment = this._copyObject(branch.end);
          segment.dropped_on = seconds - checked_branches.slice(0, checked_branches.length - 1)
            .reduce((acc, val) => acc + val, 0);
          return segment;
        }
    }
  }

  getNextSegment(): null | Segment {
    if (this.current_segment.branch.role === 'end') {
      //console.log('current_segment is the end of the current branch.');
      return null;
    }
    return this.current_branch.end;
  }

  goToNextSegment(): Segment {
    if (
      this._isSegmentTheBegginingOfTheBranch(this.current_segment) ||
      this._isSegmentAutonomous(this.current_segment)
    ) {
      return this.current_branch.end;
    }
    return undefined;
  }

  goToPrevSegment(): Segment {
    if (this._isSegmentTheEndOfTheBranch(this.current_segment)) {
      this.current_segment = this.current_branch.beggining;
      
      return this.current_branch.beggining;
    }
    if (
      this._isSegmentTheBegginingOfTheBranch(this.current_segment) ||
      this._isSegmentAutonomous(this.current_segment)
      ) {
      const new_current_branch = this._copyObject(this.previous_branches[this.previous_branches.length - 1]);
      this.current_branch = new_current_branch;
      this.previous_branches = this.previous_branches.filter(
        branch => branch.beggining.id !== new_current_branch.beggining.id
        );
      this.current_segment = new_current_branch.end;
      return new_current_branch.end;
    }
  }

  getDurationOfTimeline(): number {
    if (!this.current_branch) {
      return 0;
    }
    const previous_duration = this.getDurationOfPreviousBranches();
    if (
      this._isSegmentAutonomous(this.current_branch.beggining) ||
      this._isSegmentAutonomous(this.current_branch.end)
    ) {
      return previous_duration + this.current_branch.beggining.duration;
    }
    return previous_duration + this.current_branch.beggining.duration + this.current_branch.end.duration;
  }

  getDurationOfPreviousBranches(): number {
    let acc = 0;
    for (let branch of this.previous_branches) {
      if (this._isSegmentAutonomous(branch.beggining)) {
        acc += branch.beggining.duration;
        continue;
      }
      acc += branch.beggining.duration;
      acc += branch.end.duration;
    }
    return acc;
  }

  getDurationOfPreviousBranchesWithCurrentPosition(): number {
    const previous_duration = this.getDurationOfPreviousBranches();

    if (
      this._isSegmentTheBegginingOfTheBranch(this.current_segment) ||
      this._isSegmentAutonomous(this.current_segment)
      ) {
      return previous_duration;
    }
    if (this._isSegmentTheEndOfTheBranch(this.current_segment)) {
      return previous_duration + this.current_branch.beggining.duration;
    }
  }

  update(current_segment: Segment): void {

    if (!this.current_segment || !current_segment || !current_segment.branch) {
      return;
    }

    if (this._isSplittingOrNormalSegment(current_segment)) {
      //console.log('Splitting file recieved. Skipping...');
      return;
    }

    if (this._isSegmentAlreadySeen(current_segment)) {
      this.updateBranches(current_segment);
      this.showState();
      return;
    }
    
    if (this._isSegmentAutonomous(current_segment)) {
      this.previous_branches.push(this.current_branch);
      this.current_branch = {
        beggining: current_segment,
        middle: undefined,
        end: current_segment
      };
      this.current_segment = this._copyObject(current_segment);
      this.showState();
      return;
    }

    if (current_segment.id !== this.current_segment.id) {
      if (this._isSegmentTheEndOfTheBranch(current_segment)) {
        this.previous_branches.push(this.current_branch);
        this.current_branch = {
          beggining: this._findBranchItem(current_segment.branch.id as string, 'beggining'),
          middle: undefined,
          end: this._copyObject(current_segment)
        };
        this.current_branch.end = current_segment;
        this.current_segment = this._copyObject(current_segment);
      }

      if (this._isSegmentTheBegginingOfTheBranch(current_segment)) {
        this.previous_branches.push(this.current_branch);
        this.current_branch = {
          beggining: current_segment,
          middle: undefined,
          end: this._findBranchItem(current_segment.branch.id as string, 'end')
        };
        this.current_segment = this._copyObject(current_segment);
      }
      this.showState();
    }
  }

  _findBranchItem(branch_id: string | string[], role: BranchItemRole): Segment {
    let id = branch_id;
    if (Array.isArray(branch_id)) {
      if (branch_id.length === 0) {
        return;
      }
      id = branch_id[0].slice();
    }
    const found_segment = this.scenario.find(seg => {
      if (
        seg.branch
        ) {
          if (
            seg.branch.id === id ||
            seg.branch.id.includes(id as string)
          ) {
            if (seg.branch.role === role) {
              return seg;
            }
          }
        }
    });

    if (found_segment) {
      return found_segment;
    }

    if (this.mode === 'silent') {
      return this.scenario.find(s => s.id.includes('NI'));
    }

    throw `Segment with branch_id: ${branch_id} and role: ${role} not found`
  }

  _isSegmentTheEndOfTheBranch(segment: Segment): boolean {
    if (this.mode === 'silent') {
      return false;
    }
    if (!segment) {
      throw 'segment is undefined';
    }

    if (segment.branch && segment.branch.role === 'end') {
      return true;
    }
    return false;
  }

  _isSegmentTheBegginingOfTheBranch(segment: Segment): boolean {
    if (this.mode === 'silent') {
      return false;
    }
    if (!segment) {
      throw 'segment is undefined';
    }

    if (segment.branch && segment.branch.role === 'beggining') {
      return true;
    }
    return false;
  }

  _copyObject(obj: object) {
    return JSON.parse(JSON.stringify(obj));
  }

  _isSegmentAutonomous(segment: Segment) {
    if (this.mode === 'silent') {
      return false;
    }
    if (!segment) {
      throw 'segment is undefined';
    }

    if (segment.branch && segment.branch.role === 'all') {
      return true;
    }
    return false;
  }

  getPrevSegment(return_from_splitting_segment?: boolean): Segment {
    if (!this.current_segment) {
      return undefined;
    }
    if (return_from_splitting_segment) {
      //console.log('branchManager.return_from_splitting_segment');
      return this.current_segment;
    }
    if (this._isSegmentTheEndOfTheBranch(this.current_segment)) {

      return this.current_branch.beggining;
    }
    if (
      this._isSegmentTheBegginingOfTheBranch(this.current_segment) ||
      this._isSegmentAutonomous(this.current_segment)
    ) {
      if (this.previous_branches.length) {
        return this.previous_branches[this.previous_branches.length - 1].end;
      }
      return undefined;
    }
  }

  _isSegmentAlreadySeen(segment: Segment): boolean {
    for (let branch of [...this.previous_branches, this.current_branch]) {
      if (
        branch.beggining.id === segment.id ||
        branch.end.id === segment.id
        ) {
          return true;
        }
    }
    return false;
  }

  updateBranches(new_current_segment: Segment) {
    const new_previous_branches = [];

    for (let branch of [...this.previous_branches, this.current_branch]) {
      const { beggining, end } = branch;
      if (
        beggining.id === new_current_segment.id ||
        end.id === new_current_segment.id
        ) {
        this.current_branch = this._copyObject(branch);
        this.current_segment = this._copyObject(new_current_segment);
        break;
      }
      else {
        new_previous_branches.push(branch);
      }
    }
    this.previous_branches = new_previous_branches;
  }

  testViewOfDuration() {
    return [...this.previous_branches, this.current_branch];
  }

  _isSplittingOrNormalSegment(segment: Segment): boolean {
    if (
      SPLITTING_FILES.includes(segment.id) ||
      segment.id === 'NI' ||
      segment.id.includes('NI')
    ) {
      return true;
    }
    return false;
  }

  showState() {
    return;
    const labels = [
      {
        name: 'Previous branches: ',
        value: this.previous_branches
      },
      {
        name: 'Current branch: ',
        value: this.current_branch
      },
      {
        name: 'Current segment: ',
        value: this.current_segment
      }];
    //console.log('______BranchManager state______')
    labels.forEach(label => console.log(label.name, label.value));
  }
}

export default BranchManager;