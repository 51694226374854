import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from './store';
import { RootState } from '../types';

/**
 * Typed redux hooks. They're already know all about our state
 * and middleware that we had defined in our store.
 * More: https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
 */

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();