import ControlbarBtn from './controlbar-btn';
import { connect } from 'react-redux';
import {
  setMode,
  prevSegment,
  nextSegment,
  showPopup,
  showControlBar
} from '../state/action-creators';
import PlayerController from '../player/controller';
import { useEffect, useRef, useState } from 'react';
import { CONTROLBAR_ID } from './buttons/fullscreen';
import { useIdleTimer } from 'react-idle-timer';
import { Player } from '../player';

const cb_hide_timeout = 3000;

const ImmersiveIcon = ({ mode }) => <div className = { `immersive-icon immersive-icon${mode === 'immersive' ? '--active' : ''}` }></div>

const ViewMenu = ({
  options,
  selected,
  
}) => {
  return (
    <div className = 'view-type-menu'>
      <div className = 'view-type-menu-options'>
        {
          options && Array.isArray(options) && options.map((opt) => (
            <div
            key = { opt.value }
            className = { `view-type-menu-option ${selected === opt.value ? 'view-type-menu-option--active' : ''}` }>
              <span>
                {
                  opt.name
                }
              </span>
            </div>
          ))
          
        }
      </div>
    </div>
  );
};

const content = {
  ru: {
    title: 'Тестовое видео',
    blocked: 'Кнопка заблокирована',
    next_story: 'Следующий сегмент',
    prev_story: 'Предыдущий сегмент',
    immersive_tooltip: (mode) =>  `${mode === 'immersive' ? 'Выключить' : 'Включить'} иммерсивный режим?`,
    popup: {
      title: 'Вы собираетесь выключить иммерсивный режим',
      btn_cancel: 'Отмена',
      btn_off: 'Выключить'
    },
    movie_menus: 'Меню сюжета',
    immersive_not_available: 'Иммерсивный режим для данного видео недоступен'
  },
  en: {
    title: 'Voice word test movie',
    blocked: 'Button blocked',
    next_story: 'Next story selector',
    prev_story: 'Prev story selector',
    immersive_tooltip: (mode) => `Switch ${mode === 'immersive' ? 'off' : 'on'} immersive expirience`,
    popup: {
      title: "You are about to switch off immersive experience",
      btn_cancel: 'Cancel',
      btn_off: 'Switch off'
    },
    movie_menus: 'Movie menus',
    immersive_not_available: 'Immersive mode is not available for this video'
  }
}

const Controlbar = ({
  mode,
  setMode,
  id,
  prevSegment,
  nextSegment,
  disabled_buttons,
  active_player_id,
  isMobile,
  showPopup,
  first_interaction,
  show_controlbar,
  language,
  showControlBar,
}) => {
  const [isActive, setIsActive] = useState(true);
  const disabled_immersive_btn = PlayerController.isSplittingFilePlaying(active_player_id);

  const ref = useRef(null);
  const cb_ref = useRef(null);
  const handleImmersiveClick = () => {
    if (mode === 'normal') {
      setMode('immersive');
    }
    if (mode === 'immersive') {
      showPopup({
        title: content[language].popup.title,
        actions: [
          {
            label: content[language].popup.btn_cancel,
            cb: () => showPopup(undefined)
          },
          {
            label: content[language].popup.btn_off,
            cb: () => {
              setMode('normal');
              showPopup(undefined);
            }
          }
        ]
      });
    }
  };

  useEffect(() => {
    if (!cb_ref || !cb_ref.current) return;

    if (show_controlbar.top) {
      cb_ref.current.classList.add('u-on-top-1-r');
      cb_ref.current.classList.remove('u-on-top-1');
      return;
    }
    cb_ref.current.classList.add('u-on-top-1')
    cb_ref.current.classList.remove('u-on-top-1-r');
  }, [show_controlbar.top, cb_ref.current]);
  
/*
  useOnClickOutside(cb_ref, (e) => {
    const className = e.target.className;
    if (className.includes('rcp-top') || className.includes('controlbar')) {
      setIsActive(true);
      return;
    }
    setIsActive(false);
  }, !isMobile);
  */

useEffect(() => {
  if (first_interaction) {
    timer.start();
  }
}, [first_interaction]);

function timeout(s: number) {
  return new Promise((res, rej) => {
    const timeout = setTimeout(() => {
      res(true);
      clearTimeout(timeout);
    }, s);
  })
}

  async function _hideBar() {
    await timeout(cb_hide_timeout);
    showControlBar({
      top: false
    });
  }
  const onIdle = () => {
    if (!first_interaction) {
      return;
    }
    showControlBar({
      top: false
    });
  };

  const onAction = (e) => {
    showControlBar({
      top: true
    });
  };

  const timer_events = isMobile ? ['click'] : ['mousemove'];
  const el = ref.current;

  const timer = useIdleTimer({
    onIdle,
    onAction,
    element: el ? el : document,
    timeout: 3000,
    //@ts-ignore
    events: timer_events,
    //stopOnIdle
  });
  const veil_height = isMobile ? show_controlbar.top ? '50px' : '50px' : '150px';
  const interaction = (e) => {
    e.preventDefault();
    const _player = Player.getPlayer();
    if (!_player) {
      return;
    }
    const player = _player.player_;
    if (!player) {
      return;
    }

    if (!player.hasStarted()) {
      return player.play();
    }

    if (Player.playing()) {
      player.pause();
      //onMobileClick(e);
      return;
    }
    player.play();
    //onMobileClick(e);
  }

  return (
    <>
    {
      mode === 'initial' && false ?
      <></>
      :
      <div
      id = { `${CONTROLBAR_ID}-top` }
      ref = { ref }
      data-id = { id } className = { `controlbar-top` }>
      <div
      onClick = { isMobile ? () => {} : interaction }
      style = {{
          position: 'absolute',
          width: '100%',
          left: 0,
          top: 0,
          //height: !show_bottom_controlbar ? '400px' : '250px',
          height: veil_height,
          //background: 'red'
        }}></div>
        <div
        ref = { cb_ref }
        className = { `controlbar-top_content` }>
        <div className = 'controlbar-top-section'>
        <span>{ content[language].title }</span>
      </div>
      <div className = 'controlbar-top-section'>
        {
          !PlayerController._isNormalOrSplittingSegmentPlaying(active_player_id) ?
          <>
            <ControlbarBtn
            isMobile = { isMobile }
            label = {{
              name: '',
              icon: () => <div className = { `icon immersive-icon-prev` }></div>
            }}
            onClick = { disabled_buttons.prev_btn ? () => {} : () => prevSegment() }
            direction = 'bottom'
            tooltipText = { disabled_buttons.prev_btn ? content[language].blocked : content[language].prev_story }
            disabled = { disabled_immersive_btn || disabled_buttons.prev_btn }
            classes = { ['margin-right-bg'] }
            />
            <ControlbarBtn
            isMobile = { isMobile }
            label = {{
              name: '',
              icon: () => <div className = { `icon immersive-icon-next` }></div>
            }}
            onClick = { disabled_buttons.next_btn ? () => {} : () => nextSegment() }
            direction = 'bottom'
            tooltipText = { disabled_buttons.next_btn ? content[language].blocked : content[language].next_story }
            disabled = { disabled_immersive_btn || disabled_buttons.next_btn }
            classes = { ['margin-right-bg'] }
            />
          </>
          :
          <></>
        }
        <ControlbarBtn
        isMobile = { isMobile }
        label = {{
          name: '',
          icon: ImmersiveIcon
        }}
        onClick = { () => {} }
        direction = { 'bottom' }
        tooltipText = { content[language].immersive_not_available }
        disabled = { true }
        //classes = { ['button-big'] }
        />
      </div>
        </div>
    </div>
    }
    </>
  );
};

export default connect((state: any) => ({
  mode: state.root.mode,
  disabled_buttons: state.player.disabled_buttons,
  active_player_id: state.root.active_player_id,
  show_controlbar: state.player.show_controlbar,
  isMobile: state.device.isMobile,
  language: state.root.language,
  first_interaction: state.root.first_interaction
}),{
  setMode,
  prevSegment,
  nextSegment,
  showPopup,
  showControlBar
})(Controlbar);