import {
  SEGMENT,
  SegmentAction
} from '../actions';
import {
  SegmentState
} from '../../types';

export const initial_state: SegmentState = {
  voice_recognition_enabled: false,
  choose_mood_enabled: false,
};

export default function segment(state: SegmentState = initial_state, action: SegmentAction) {
  switch(action.type) {
    case SEGMENT.SET_CHOOSE_MOOD_ENABLED:
      return {
        ...state,
        choose_mood_enabled: action.payload
      };
    case SEGMENT.SET_VOICE_RECOGNITION_ENABLED:
      return {
        ...state,
        voice_recognition_enabled: action.payload
      };
    default:
      return state;
  }
};