import { memo, useEffect, useRef, useState } from 'react';
import { Player } from '../../player';
import styles from './volume.module.scss';
import { ReactComponent as OffIcon } from '../../images/player/volume-off.svg';
import { ReactComponent as MiddleIcon } from '../../images/player/volume-middle.svg';
import { ReactComponent as OnIcon } from '../../images/player/volume.svg';
import RangeInput from '../range-input';
import BtnVeil from './veil';
import getHeight from '../../utils/get-height';
import { VideoJsPlayer } from 'video.js';

interface IVolumeBtn {
  volume: number
  setVolume: any
  vjs_player?: VideoJsPlayer
}

const VolumeBtn = ({
  volume,
  setVolume,
  vjs_player
}: IVolumeBtn) => {
  const [slider_dims, set_slider_dims] = useState({
    width: 60,
    height: 60
  });
  const VolumeIcon = ({ volume }: { volume: number }) => {
    if (volume <= 0) {
      return <OffIcon className = { styles.v_off } />
    }
    if (volume <= 70) {
      return <MiddleIcon className = { styles.v_middle } />
    }
    return <OnIcon className = { styles.v_on } />
  }

  function onIconClick() {
    if (volume === 0) {
      _setVolume(60);
      return;
    }
    _setVolume(0);
  }
  const _setVolume = (_volume: number) => {
    const p = vjs_player || Player;
    let volume = _volume;
    if (vjs_player) {
      volume = parseFloat((_volume / 100).toString().slice(0, 3));
    }

    p.volume(volume);
    setVolume(_volume);
  }

  const height = getHeight(20);

  function getButtonWidth() {
    if (!b_r) {
      return 60;
    }
    if (!b_r.current) {
      return 60;
    }
    return b_r.current.getBoundingClientRect().width;
  }
  const b_r = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const c = b_r.current;
    if (c) {
          
      const width = getButtonWidth() / 100 * 40;
      const _height = height / 100 * 80;

      set_slider_dims({
        width,
        height: _height
      });
    }
  }, [b_r.current]);
  return (
    <div
    ref = { b_r }
    className = { styles.v }
    
    >
      <VolumeIcon
      volume = { volume }
      />
      <div className = { styles.v_content_wrapper }>
        <div className = { styles.v_content }
        style = {{
          height
        }}
        >
          {
            <RangeInput
            player = { vjs_player || Player }
            volume={volume}
            setVolume={ vjs_player ? _setVolume : setVolume}
            dimensions = {slider_dims}
            />
          }
        </div>
      </div>
      <BtnVeil
      onClick = { onIconClick }
      value = { 'any' }
      />
    </div>
  )
}

export default VolumeBtn;