import QualityLevels from './buttons/quality-levels';
import Language from './buttons/language';
import Subtitles from './buttons/subtitles';
import React, { useRef } from 'react';
import { VideoJsPlayer } from 'video.js';

interface MobileMenuProps {
  active_player_id: string
  openedMenu: any
  setOpenedMenu: any
  show_bottom_controlbar: boolean
  setQualityLevel: any
  quality_level: any
  language: any
  isIos: boolean
  setLanguage: any
  subtitles: any
  showSubtitles: any
  vjs_player?: VideoJsPlayer
}

const MobileMenu = ({
  active_player_id,
  openedMenu,
  setOpenedMenu,
  show_bottom_controlbar,
  setQualityLevel,
  quality_level,
  language,
  isIos,
  setLanguage,
  subtitles,
  showSubtitles,
  vjs_player
}: MobileMenuProps) => {
  const active = '--mm-opened';
  const closed = '--mm-closed';

  function onIconClick() {
    const element = content_ref.current;
    if (element.className.includes(active)) {
      element.classList.add(closed);
      element.classList.remove(active);
      setOpenedMenu(undefined);
      return;
    }
    element.classList.add(active);
    element.classList.remove(closed);
    setOpenedMenu('x');
  }

  function closeMenu() {
    const el = content_ref.current;
    //el.classList.add(closed);
    el.classList.remove(active);
    setOpenedMenu(undefined);
  }

  React.useEffect(() => {
    if (!show_bottom_controlbar) {
      closeMenu();
    }
  }, [show_bottom_controlbar]);
  const content_ref = useRef(null);
  return (
    <div
    style = {{
      width: '100%',
      height: '100%'
    }}
    className = { 'mm_container' }>
      <div
      onClick = { onIconClick }
      style = {{
        width: '100%',
        height: '100%'
      }}
      className = { 'mm_icon icon-settings' }>
      </div>
      <div
      id = { 'mm_id' }
      ref = { content_ref }
      style = {{
        display: 'flex',
        position: 'absolute',
        bottom: 'calc(100% + 4px)',
        width: '100%',
        height: 0,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'end',
        opacity: 0,
        visibility: 'hidden',
        overflow: 'hidden !important'
      }}
      className = { 'mm_content' }>
        <div>
      {
          !isIos &&
          <div className = { 'mm_content_item' }>
          
            <QualityLevels
            quality_level = { quality_level }
            setQualityLevel = { setQualityLevel }
            openedMenu = { openedMenu }
            setOpenedMenu = { setOpenedMenu }
            language = { language }
            menu_id = { 'quality_levels' }
            show_bottom_controlbar = { show_bottom_controlbar }
            isIos = { isIos }
            isMobile = { true }
            isSubMenu = { true }
            vjs_player = { vjs_player }
            />
          
        </div>
        }
        <div className = { 'mm_content_item' }>
          <Language
          menu_id = { 'language' }
          show_bottom_controlbar = { show_bottom_controlbar }
          openedMenu = { openedMenu }
          setOpenedMenu = { setOpenedMenu }
          language = { language }
          setLanguage = { setLanguage }
          isMobile = { true }
          isSubMenu = { true }
          />
        </div>
        <div className = { 'mm_content_item' }>
          <Subtitles
          menu_id = { 'subtitles' }
          show_bottom_controlbar = { show_bottom_controlbar }
          openedMenu = { openedMenu }
          setOpenedMenu = { setOpenedMenu }
          subtitles = { subtitles }
          showSubtitles = { showSubtitles }
          isMobile = { true }
          isSubMenu = { true }
          />
        </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MobileMenu);