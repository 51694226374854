import {
  Root
} from '../../types';
import {
  GLOBAL,
  GlobalAction
} from '../actions';

export const initial_state: Root = {
  watched: [],
  pool: [],
  players: [],
  active_player_id: '',
  splitting_players: [],
  scenario: [],
  mode: 'initial',
  view_type: 'movie',
  registered_players: [],
  quality_level: 'auto',
  show_bg: false,
  subtitles: {
    show: false,
    language: 'en'
  },
  rewinding: {
    actual: false,
    seconds: 0
  },
  reserved_player: {
    id: '',
    label: '',
    segment: {
      duration: 0,
      id: '',
      linked_with: [],
      default: false,
      //@ts-ignore
      subtitles: {
        ru: '',
        en: ''
      },
      src: ''
    }
  },
  language: 'en'
};

export default function RootReducer(state: Root = initial_state, action: GlobalAction) {
  switch(action.type) {
    case GLOBAL.FIRST_INTERACTION:
      return {
        ...state,
        first_interaction: action.payload
      }
    case GLOBAL.SET_TRANSITION_PLAYER:
      return {
        ...state,
        transition_player: action.payload
      }
    case GLOBAL.SHOW_SUBTITLES:
      return {
        ...state,
        subtitles: action.payload
      };
    case GLOBAL.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case GLOBAL.SHOW_POPUP:
      return {
        ...state,
        popup: action.payload
      };
    case GLOBAL.SET_SHOW_BG:
      return {
        ...state,
        show_bg: action.payload
      };
    case GLOBAL.SET_FOUND_SEGMENT:
      return {
        ...state,
        found_segment: action.payload
      };
    case GLOBAL.SET_REWINDING:
      return {
        ...state,
        rewinding: action.payload
      };
    case GLOBAL.SET_RESERVED_PLAYER_STATE:
      return {
        ...state,
        reserved_player: action.payload
      };
    case GLOBAL.SET_QUALITY_LEVEL:
      return {
        ...state,
        quality_level: action.payload
      };
    case GLOBAL.UNREGISTER_PLAYER:
      return {
        ...state,
        registered_players: state.registered_players.filter(player => player.player_id !== action.payload)
      };
    case GLOBAL.REGISTER_PLAYER:
      return {
        ...state,
        registered_players: Array.from(new Set(state.registered_players.concat(action.payload)))
      };
    case GLOBAL.SET_VIEW_TYPE:
      return {
        ...state,
        view_type: action.payload
      };
    case GLOBAL.SET_MODE:
      return {
        ...state,
        mode: action.payload
      };
    case GLOBAL.UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      };
    case GLOBAL.NEXT_SEGMENT:
      return {
        ...state,
        //watched: state.watched.concat(state.scenario.find(scen => scen.id === state.active_player_id)),
        active_player_id: action.payload,
      };
      case GLOBAL.PREV_SEGMENT:
        return {
          ...state,
          //watched: state.watched.concat(state.scenario.find(scen => scen.id === state.active_player_id)),
          active_player_id: action.payload,
        };
    case GLOBAL.SET_SCNERATIO:
      return {
        ...state,
        scenario: action.payload
      };
    case GLOBAL.CHANGE_ACTIVE_PLAYER:
      return {
        ...state,
        active_player_id: action.payload,
      };
    case GLOBAL.SET_SEGMENT_FOR_ACTIVE_PLAYER:
      return {
        ...state,
        active_player_id: action.payload
      };
    default:
      return state;
  }
};