import { Mode, Segment } from "../types";

const splitting_files = {
  INTRO_SF_IMM1: {
    id: 'INTRO_SF_IMM1',
    duration: 5,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_IMM1_1080.m3u8'
  },
  INTRO_SF_IMM2: {
    id: 'INTRO_SF_IMM2',
    duration: 7,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_IMM2_1080.m3u8'
  },
  INTRO_SF_NORM1: {
    id: 'INTRO_SF_NORM1',
    duration: 9,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_NORM1_1080.m3u8'
  },
  INTRO_SF_NORM2: {
    id: 'INTRO_SF_NORM2',
    duration: 4,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_NORM2_1080.m3u8'
  },
  INTRO_SF_NORM3: {
    id: 'INTRO_SF_NORM3',
    duration: 6,
    subtitles: '',
    linked_with: [],
    default: false,
    src: 'https://d2ez8akk35wez5.cloudfront.net/videos_reborn/splitting_files/INTRO_SF_NORM3_1080.m3u8'
  }
};

export const SPLITTING_FILES = Object.keys(splitting_files).map(key => splitting_files[key].id);

const to_immersive = [splitting_files.INTRO_SF_NORM2.id, splitting_files.INTRO_SF_NORM3.id];
const to_normal = [splitting_files.INTRO_SF_IMM1.id, splitting_files.INTRO_SF_IMM2.id];

const splitting_file_prefix = 'SPLIT_';
const name_in_local_storage = 'PLAYED_FRAGMENTS';
const max_size_local_storage = 30;

class SplittingFilesManager {
  played_fragments = [];
  constructor({
    played_fragments
  }: {
    played_fragments: Segment[]
  }) {
    if (played_fragments) {
      this.played_fragments = played_fragments;
    }
  }
  /**
   * Get already watched fragments
   * @returns {Array.<string>} already watched fragments
   */
  getAlreadyPlayedFragments() {
    return this.played_fragments.slice();
  }

  clearLocalStorage() {
    localStorage.setItem(name_in_local_storage, '');
  }

  _isSplittingFIle(fragment_name) {
    return Object.keys(splitting_files).includes(fragment_name);
  }
  /**
   * Add fragment_name to the localStorage
   * @param {string} fragment_name 
   */
  addPlayedFragment(fragment_name) {
    let fragment = fragment_name;
    const previous = this.getAlreadyPlayedFragments();
    previous.push(fragment);
    this.played_fragments.push(fragment_name);
  }
  /**
   * Choose what splitting file should user see based on
   * previous watched fragments
   * @param {string} chosen_mode that you r going to turn on
   * @returns {string} the name of splitting file
   */
  chooseFragment({
    chosen_mode,
    previous
  }: {
    previous?: Segment[],
    chosen_mode: Mode
  }) {
    const NI = 'NI';
    const previous_keys = previous.map(segment => segment.id);
    let mode = chosen_mode === 'initial' ? 'immersive' : chosen_mode.slice();
    const NI_played = previous_keys.some(fragment => typeof fragment !== 'string' ?
    //@ts-ignore
      false : fragment.includes(NI));

    if (
      mode === 'normal'
    ) {
      return this.oneOfTwoInTurn(
        previous_keys, to_normal
        );
    }
    if (
      mode === 'immersive'
    ) {
      if (NI_played) {
        if (previous_keys.filter(key => key === splitting_files.INTRO_SF_NORM1.id).length < 1) {
          return splitting_files.INTRO_SF_NORM1.id;
        }
        return this.oneOfTwoInTurn(
          previous_keys, to_immersive
            );
      }
      return splitting_files.INTRO_SF_NORM1.id;
    }
  }
  /**
   * Select one of the two fragments each after other in turn
   * @param {Array.<string>} watched already watched fragments
   * @param {Array.<string>} fragments you need to show
   * @returns {string} the fragment that should be played
   */
  oneOfTwoInTurn(watched, fragments) {
    const last_fragment = watched.reverse().slice().find(fragment =>
      fragment === fragments[0] || fragment === fragments[1]
    );
    if (last_fragment && last_fragment === fragments[0]) {
      return fragments[1];
    }
    if (last_fragment && last_fragment === fragments[1]) {
      return fragments[0];
    }

    return fragments[0];
  }
 
  /**
   * @param {string} fragment 
   * @returns string without prefix
   */
  _replacePrefix(fragment) {
    return fragment.replace(splitting_file_prefix, '');
  }
  /**
   * Choose what player to turn on to show splitting file
   * based on mode you transition to and previous watched
   * segments
   * @param {string} chosen_mode immersive or normal
   * @param {Array.<object>} players array of all players
   * @returns {number} index of target player
   */
  choosePlayer({
    chosen_mode,
    players
  }) {
    const target_fragment = this.chooseFragment({
      chosen_mode,

    });

    let fallback;
    // What player is ready to show required splitting file?
    const target_player = players.findIndex(
      (player, index) => {
        if (player.hasOwnProperty('segment')) {
          if (player.segment.model.id === target_fragment) {
            return true;
          }
          else {
            if (this._isSplittingFIle(player.segment.model.id)) {
              fallback = index;
            }
          }
        }
      }
    );
    this.addPlayedFragment(target_fragment ? target_fragment : fallback);
    return target_player !== -1 ? target_player : fallback;
  }
  /**
   * What video should play provided player next
   * @param {object} transitionPlayer what player should update it's sourse
   * to load next splitting file
   * @param {Array.<object>} transitionSegments all segments that are currently
   * exist in the state  
   * @returns {object} the source with the segment that should be played next
   */
  chooseSegmentForUpdate({
    transitionPlayer,
    transitionSegments
  }) {
    const previous = this.getAlreadyPlayedFragments();
    let target_fragment_id;
    let target_segment;
    const id = transitionPlayer.segment.model.id;
    if (to_normal.includes(id)) {
      target_fragment_id = this.oneOfTwoInTurn(
        previous,
        to_normal
      );
    }
    else {
      target_fragment_id = this.oneOfTwoInTurn(
        previous,
        to_immersive
      );
    }

    transitionSegments.forEach(segment => {
      if (segment.segment.model.id === target_fragment_id) {
        target_segment = segment;
        target_segment.currentActiveSegment = target_fragment_id;
      } 
    });
    return target_segment;
  }
}

export default SplittingFilesManager;