import {
  Segment,
  PlayerState,
  BasicFunctionArgs,
  Root,
  RootState,
  PlayerLabel,
  UpdatePlayerState
} from '../types';
import {
  setActivePlayer,
  updateState,
  setReservedPlayerState,
  setRewinding,
  prevSegment,
  updateProgress,
  setSeekedTime,
  nextSegment,
  setFoundSegment
} from '../state/action-creators';
import { AppAction, GLOBAL, PLAYER, TIMELINE } from '../state/actions';
import _SplittingFilesManager from '../services/manage-splitting-files';
import _SnapshotManager from '../services/snapshot';
import SplittingFilesManager, { SPLITTING_FILES } from '../services/manage-splitting-files';
import _BranchManager from '../services/manage-branches';
import scenario from '../scenario';
import store from '../state/store';
import { Player } from '.';

const BranchManager = new _BranchManager(scenario, 'silent');

class PlayerController {
  scenario: Segment[] = [];
  abortedSegment: any;

  private _players: PlayerState[];

  constructor() {
  }

  get players() {
    return this._players;
  }

  set players(new_players: PlayerState[]) {
    this._players = new_players;
  }

  buildPool({
    scenario,
    active_player
  }: {
    scenario: Segment[],
    active_player: PlayerState
  }): Segment[] {
    //@ts-ignore
    const pool = [];
    if (!scenario || !active_player) {
      //console.log('no props passed to buildPool')
      return [];
    }
    const start_point = scenario.find(segment => segment.id === active_player.id);
    if (typeof start_point === 'undefined') {
      throw 'Active segment not found in the scenario';
    }
    //pool.push(start_point);
    let seg = start_point;

    for (let x = 0; x < scenario.length; x ++) {

      //@ts-ignore
      seg = this.findDefaultSegment(seg, scenario);
      
      if (seg) {
        //@ts-ignore
        if (!pool.some(segment => segment.id === seg.id)) {
          pool.push(seg);
        }
      }
    }
    return pool;
  }

  findSegmentById(id: string, scenario: Segment[]) {
    return scenario.find(segment => segment.id.includes(id));
  }

  findDefaultSegment(segment: Segment, scenario: Segment[]): Segment | undefined {
    let default_segment: Segment | undefined = undefined;
    if (segment && segment !== undefined && segment.hasOwnProperty('linked_with')) {
      for (let x = 0; x < segment.linked_with.length; x ++) {
        const seg = this.findSegmentById(segment.linked_with[x], scenario);
        if (seg && seg.default === true) {
          return seg
        }
      }
    }
    return default_segment;
  }

  handleStateUpdates(store: any, action: AppAction) {
    const state = store.getState();
    //console.log('handleStateUpdates', action.type, action.payload);
    if (
      action.payload === 'another' ||
      state.root.active_player_id === 'another'
      ) {
      return action.payload;
    }
    if (action.type === GLOBAL.SET_LANGUAGE && !action.payload) {
      const lang = state.root.language;
      return lang === 'ru' ? 'en' : 'ru';
    }


    if (action.type === PLAYER.SET_DISABLED_BUTTONS) {
      if (
        state.root.active_player_id === '1D' &&
        //@ts-ignore
        action.payload.hasOwnProperty('prev_btn') &&
        //@ts-ignore
        action.payload.prev_btn === false
      ) {
        //console.log('disable prev btn on 1D');
        return;
      }
    }

    if (action.type === GLOBAL.SET_REWINDING) {
      const seekable = action.payload.seconds;

      if (action.payload.actual === false) {
        //console.log('action.payload.actual === false');
        return action.payload;
      }
      
      if (
        this._isNormalOrSplittingSegmentPlaying(state.root.active_player_id) ||
        state.root.view_type === 'piece' && action.payload.actual === true
        ) {
        //console.log("state.root.view_type === 'piece' && action.payload.actual === true")
        store.dispatch(setSeekedTime(seekable));
        setTimeout(() => {
          store.dispatch(setSeekedTime(undefined));
        }, 100);
        return action.payload;
      }

      const active_segment_duration = this.findSegmentById(
        state.root.active_player_id,
        state.root.scenario).duration;

      const seekable_without_watched_duration = seekable - (BranchManager
        .getDurationOfPreviousBranchesWithCurrentPosition());

      const if_seekable_in_range_of_current_segment =
        seekable_without_watched_duration <= active_segment_duration && seekable_without_watched_duration >= 0;
      
      const if_seekable_behind_current_segment = seekable_without_watched_duration < 0;
      
      const if_seekable_ahead_current_segment = seekable_without_watched_duration > active_segment_duration;

      
      if (this._isNormalOrSplittingSegmentPlaying(state.root.active_player_id)) {
        //console.log('_isNormalOrSplittingSegmentPlaying');
        store.dispatch(setSeekedTime(seekable));
        setTimeout(() => {
          store.dispatch(setSeekedTime(undefined));
        }, 100);
        return;
      }

      if (if_seekable_behind_current_segment) {
        //console.log('if_seekable_behind_current_segment')
        const seekable_segment = BranchManager.getPreviousSeekableSegment(seekable);
        //seekable_segment.dropped_on = seekable_segment.dropped_on * 1000;

        if (this.isPlayerAlreadyExist(seekable_segment.id)) {
          store.dispatch(
            setFoundSegment(seekable_segment)
          );
        }
        else {
          store.dispatch(setReservedPlayerState(
            this.createPlayer(seekable_segment, 'reserved')
          ));
        }
        return action.payload;
      }

      if (if_seekable_in_range_of_current_segment) {
        //console.log('if_seekable_in_range_of_current_segment');
        store.dispatch(setSeekedTime(seekable_without_watched_duration));
        setTimeout(() => {
          store.dispatch(setSeekedTime(undefined));
        }, 100);
        return action.payload;
      }
      
      if (if_seekable_ahead_current_segment) {
        //console.log('if_seekable_ahead_current_segment');
        const target_segment = BranchManager.getNextSeekableSegment(seekable);
        if (target_segment) {
          //target_segment.dropped_on *= 1000;
          //console.log('BranchManger target: ', target_segment);
          if (
            this.isPlayerAlreadyExist(target_segment.id)
          ) {
            store.dispatch(
              setFoundSegment(target_segment)
            );
          }
          else {
            store.dispatch(setReservedPlayerState(
              this.createPlayer(target_segment, 'reserved')
            ));
          }
        }
        else {
          //@ts-ignore
          //store.dispatch(nextSegment());
          //store.dispatch(setSeekedTime())
        }
        return action.payload;
      }
    }

    if (
      action.type === GLOBAL.CHANGE_ACTIVE_PLAYER ||
      action.type === GLOBAL.SET_SEGMENT_FOR_ACTIVE_PLAYER ||
      action.type === GLOBAL.NEXT_SEGMENT ||
      action.type === GLOBAL.PREV_SEGMENT
      ) {
      let prev_segment_action = false;
      if (action.type === GLOBAL.NEXT_SEGMENT) {
        if (
          state.root.pool.length > 0 &&
          state.root.pool[0] !== undefined &&
          state.root.pool[0].id
        ) {
          action.payload = this.getNextSegment(state.root).id;
          if (action.payload === state.root.active_player_id) {
            action.payload = this.getNextSegment({
              ...state.root,
              pool: state.root.pool.filter(segment => segment.id !== action.payload)
            }).id;
          }
        }
        else 
         {
          action.payload = this.returnPreviousSegment(state.root).id;
        }
      }
      
      if (action.type === GLOBAL.PREV_SEGMENT) {
        action.payload = this.returnPreviousSegment(state.root).id;
        prev_segment_action = true;
        //BranchManager.goToPrevSegment();
      }

      if (action.payload && state.root.active_player_id !== action.payload) {
        
        BranchManager.update(this.findSegmentById(action.payload, scenario));

        store.dispatch(
          //@ts-ignore
          updateState(this.updateState(
            this.createPlayer(
              this.findSegmentById(action.payload, state.root.scenario),
              'active'
            ),
            state,
            prev_segment_action
          ))
        );
    }
    }

    if (
      action.type === GLOBAL.SET_MODE
    ) {
      const _SplittingFilesManager = new SplittingFilesManager({
        played_fragments: state.root.watched
      });
      if (state.root.mode === 'initial') {
        const choosed_mode = action.payload;
        const player = choosed_mode === 'immersive' ? 'S_1D_en' : 'S_NI_en';
        
        store.dispatch(setActivePlayer(
          player
        ));
        return action.payload;
      }
      if (action.payload === 'immersive') {
        const imm1 = state.root.watched.filter(segment => segment.id === 'INTRO_SF_IMM1');
        const segment = _SplittingFilesManager.chooseFragment({
          chosen_mode: 'normal',
          previous: state.root.watched
        });
        store.dispatch(
          setActivePlayer(segment)
        );
        return action.payload;
      }
      if (action.payload === 'normal') {
        const segment = _SplittingFilesManager.chooseFragment({
          chosen_mode: 'immersive',
          previous: state.root.watched
        });
        store.dispatch(
          setActivePlayer(segment)
        );
        return action.payload;
      }
    }

    switch(action.type) {
      case GLOBAL.NEXT_SEGMENT:

        return action.payload;
      case GLOBAL.PREV_SEGMENT:
        
        return action.payload;
      case GLOBAL.SET_SEGMENT_FOR_ACTIVE_PLAYER:
        const new_active_player = this.createPlayer(
          this.findSegmentById(action.payload, state.root.scenario),
          'active'
        );
        
        BranchManager.init({
          current_segment: new_active_player.segment
        });
        
        this.scenario = state.root.scenario.slice();
        //const players = this.createPlayers(new_active_player, state.root);
        return new_active_player.id;
        
        default:
          return action.payload;
      }
  }

  getActivePlayer(state: Root) {
        const next_active_player = state.pool.splice(0, 1)[0];
        
        return {
          id: next_active_player.id,
          segment: next_active_player
        };
  }

  updateState(active_player: PlayerState, state: RootState, previous_segment_action?: boolean) {
    let new_pool = this.buildPool({
      active_player,
      scenario: state.root.scenario
    });
    const active_player_id = (state.root.active_player_id.includes('_ru') || state.root.active_player_id.includes('_en'))
      ?
      state.root.active_player_id.slice(0, state.root.active_player_id.length - 3)
      :
      state.root.active_player_id;
    let current_segment = this.findSegmentById(active_player_id, state.root.scenario);
    if (this._isNormalOrSplittingSegmentPlaying(active_player.id)) {
      let previous_segment = this.returnPreviousSegment(state.root, true);
      previous_segment.dropped_on = state.timeline.current_segment_time;
      current_segment.dropped_on = state.timeline.current_segment_time;
      if (state.root.mode === 'immersive' || state.root.mode === 'initial') {
        new_pool = [this.findSegmentById('NI', state.root.scenario)];
      }
      if (state.root.mode === 'normal') {
        new_pool = this.buildPool({
          active_player: this.createPlayer(previous_segment, 'active'),
          scenario: state.root.scenario
        });
        new_pool = [previous_segment, ...new_pool];
      }

    }

    const prev_segment = previous_segment_action ?
      null : current_segment;

      const new_watched = prev_segment ?
      [...state.root.watched, prev_segment] : state.root.watched.slice();
      
    
    const labels = [
      {
        name: 'pool: ',
        value: new_pool
      },
      {
        name: 'watched: ',
        value: new_watched
      },
      {
        name: 'prev segment: ',
        value: prev_segment
      }
    ];

    //const active = this.isNormalSegmentPlaying(active_player.id) ? this.players.find(player => player.label === 'prev') : active_player;

    const players = this._createPlayers(active_player.id, {
      ...state.root,
      pool: new_pool,
      watched: new_watched
    });

    const new_players = players.slice().map(player => {
      return {
        ...player,
        already_used: false,
      };
    });

    this.players = new_players;
    //console.log('players::: ', new_players);
    return {
      pool: new_pool,
      players: players,
      watched: new_watched,
      next_default_segment: players.find(player => player.label === 'next'),
      prev_segment: players.find(player => player.label === 'prev'),
      active_player_id: active_player.id
    };
  }

  createPlayer(segment: Segment, label) {
    if (!segment) {
      return {
        id: 'NI',
        label: '',
        segment: this.findSegmentById('NI', scenario)
      }
      //throw 'createPlayer not recieved segment'
    }
    return {
      id: segment.id,
      label: label,
      segment: segment
    };
  }
  _replaceWithSavingPosition(prev_players, next_players, active_player) {
    const new_arr = [];
    if (!active_player) return;
    const active_index = prev_players.findIndex(pl => pl && pl.id === active_player.id);
    new_arr[active_index] = active_player;

    for (let x = 0; x < next_players.length; x ++) {
      if (!new_arr[x] && new_arr.findIndex(el => el && el.id === next_players[x].id) === -1) {
        new_arr[x] = next_players[x];
      }
    }
    return new_arr;
  }

  requestPlayerUpdate(label: PlayerLabel, id: string): UpdatePlayerState {
    const state = store.getState().root;
    const active_player_id = state.active_player_id;

    const SplittingFilesManager = new _SplittingFilesManager({
      played_fragments: state.watched
    });
    //@ts-ignore
    if (!label.includes('splitting') && label !== 'normal') {
      if (id && this.players.find(pl => pl.id === id)) {
        return {
          ...this.players[0],
          not_need_update: true
        };
      }
      //console.log('players in requestPlayerUpdate: ', this.players);
      for (let player of this.players) {
        if (!player.already_used && !this.isPlayerAlreadyExist(player.id)) {
          player.already_used = true;
          return player;
        }
      }
    }
    switch(label) {
        
      case 'splitting_0':
        /* falls through */
        let segment_id = SplittingFilesManager.chooseFragment({
          chosen_mode: 'normal',
          previous: state.watched
        });
        return this.createPlayer(
          this.findSegmentById(segment_id, state.scenario),
          'splitting'
        );
      case 'splitting_1':
        let segment = SplittingFilesManager.chooseFragment({
          chosen_mode: 'immersive',
          previous: state.watched
        });
        return this.createPlayer(
          this.findSegmentById(segment, state.scenario),
          'splitting'
        )
        //@ts-ignore
      case 'normal':
          return this.createPlayer(
            this.findSegmentById('NI', state.scenario),
            'normal'
          );
        
    }
  }
  saveAbortedSegment(segment: Segment) {
    this.abortedSegment = segment;
  }

  saveAbortedPlayer(player: PlayerState) {
    this.players[this.players.findIndex(pl => pl.id === player.id)].already_used = false;
  }

  calcTimeline(state: Root): number {
    if (!state.active_player_id) return 0;
    if (state.mode === 'normal') {
      return this.findSegmentById(state.active_player_id, state.scenario).duration;
    }
    return BranchManager.getDurationOfTimeline();
  }

  calcCurrentPosition(state: RootState, current_segment_time: number): number {
    if (state.root.view_type === 'piece') {
      return state.timeline.current_time;
    }
    const previous_duration = BranchManager.getDurationOfPreviousBranchesWithCurrentPosition();
    return previous_duration + current_segment_time;
  }

  returnPreviousSegment(state: Root, return_from_splitting_segment?: boolean) {
    
    const segment = BranchManager.getPrevSegment(return_from_splitting_segment);
    //console.log('_______________SEGMENT: ',segment);
    if (!segment) {
      return this.findSegmentById('1D', state.scenario);
    }
    return segment;
  }

  getNextSegment(state: Root) {
    const segment = state.pool.slice().splice(0,1)[0];

    if (segment === undefined) {
      console.warn('Next segment from pool is undefined. Return to 1D');
      return this.findSegmentById('1D', state.scenario);
    }
    return segment;
  }

  getWatchedDuration(state: Root) {
    return this.removeDuplicatesAndSplittingFiles(state.watched)
      .reduce((acc, val) => acc + val.duration, 0);
  }

  removeDuplicatesAndSplittingFiles(array: Segment[]): Segment[] {
    const without_dups = [];
    for (let segment of array) {
      if (
        !without_dups.find(seg => seg.id === segment.id && !SPLITTING_FILES.includes(seg.id))
      ) {
        without_dups.push(segment);
      }
    }
    return without_dups;
  }

  _createPoolUntilInteractiveSegment(pool: Segment[]): Segment[] {
    const pool_with_interactive_segment = [];
    for (let x = 0; x < pool.length; x ++) {
      const current_segment = pool[x];
      pool_with_interactive_segment.push(current_segment);
      if (current_segment.type === 'interactive' || current_segment.type === 'parallel') {
        break;
      }
    }
    return pool_with_interactive_segment;
  }

  testViewOfDuration() {
    return BranchManager.testViewOfDuration();
  }

  isPlayerAlreadyExist(id: string) {
    return document.getElementById(id);
  }

  getSeekableSegment(seekable: number) {
    const state = store.getState();
    const timeline_duration = BranchManager.getDurationOfTimeline();
    let seekable_segment;
    let segment_current_time;

    const seekable_without_watched_duration = seekable - BranchManager
      .getDurationOfPreviousBranchesWithCurrentPosition();

    const active_segment = this.findSegmentById(
      state.root.active_player_id,
      state.root.scenario);
    const active_segment_duration = active_segment.duration;

    const if_seekable_in_range_of_current_segment =
      seekable_without_watched_duration <= active_segment_duration &&
      seekable_without_watched_duration >= 0;
  
    const if_seekable_behind_current_segment = seekable_without_watched_duration < 0;
    
    const if_seekable_ahead_current_segment = seekable_without_watched_duration > active_segment_duration;

    if (if_seekable_behind_current_segment) {
      seekable_segment = BranchManager.getPreviousSeekableSegment(seekable);
      segment_current_time = seekable_segment.dropped_on;
    }

    if (if_seekable_in_range_of_current_segment) {
      seekable_segment = active_segment;
      segment_current_time = seekable_without_watched_duration;
    }

    if (if_seekable_ahead_current_segment) {
      seekable_segment = BranchManager.getNextSeekableSegment(seekable);
      segment_current_time = seekable_segment.dropped_on;
    }

    return {
      timeline_duration,
      seekable_segment,
      segment_current_time
    };
  }

  _isNormalOrSplittingSegmentPlaying(id: string): boolean {
    if (SPLITTING_FILES.includes(id) || id === 'NI' || id.includes('NI')) {
      return true;
    }
    return false;
  }

  getPreviousDuration() {
    return BranchManager.getDurationOfPreviousBranchesWithCurrentPosition();
  }

  isPreviousSegmentWasSplitting(watched: Segment[]) {
    if (!watched.length) {
      return false;
    }
    const last_segment = watched[watched.length -1];
    if (SPLITTING_FILES.includes(last_segment.id)) {
      return true;
    }
  }

  _createPlayers(active_player_id: string, state: Root) {
    const players: PlayerState[] = [];
    const scenario = state.scenario.slice();
    const active_segment = this.findSegmentById(active_player_id, scenario);
    
    const next_segment = this.getNextSegment(state);
    const prev_segment = this.returnPreviousSegment(state);

    if (next_segment && !players.find(player => player.id === next_segment.id)) {
      players.push(this.createPlayer(next_segment, 'next'));
    }
    if (prev_segment && !players.find(player => player.id === prev_segment.id)) {
      players.push(this.createPlayer(prev_segment, 'prev'));
    }
    if (!players.find(player => player.id === active_segment.id)) {
      players.push(this.createPlayer(active_segment, 'active'));
    }
    
    active_segment.linked_with.forEach(segment_id => {
      if (players.find(player => player.id === segment_id)) {
        return;
      }
      players.push(this.createPlayer(
        this.findSegmentById(segment_id, scenario),
        'linked'
        ));
    });
    return players;
  }

  isPreviousPlayer(id: string) {
    const state = store.getState().root;
    //@ts-ignore
    return this.returnPreviousSegment(state).id === id;
  }

  isNextPlayer(id: string) {
    const state = store.getState().root;
    //@ts-ignore
    return this.getNextSegment(state).id === id;
  }

  isSplittingFilePlaying(id: string) {
    if (SPLITTING_FILES.includes(id)) {
      return true;
    }
    return false;
  }

  isNormalSegmentPlaying(id: string) {
    return id === 'NI' || id.includes('NI');
  }
}

export default new PlayerController();