import React, {
  ReactElement,
  useEffect,
  useState
} from 'react';
import Tooltip from './tooltip';
import BtnVeil from './buttons/veil';

interface ControlbarBtnProps {
  children?: ReactElement
  label?: any
  onClick?: Function
  direction?: 'top' | 'bottom' | 'bottom-right'
  tooltipText?: string
  no_bg?: boolean
  classes?: string[]
  disabled?: boolean
  isMobile: boolean
  [x: string]: any
  arrow?: boolean
  setOpenedMenu?: any
  menu_id?: string
  show_bottom_controlbar?: boolean
  openedMenu?: string
}

const ArrowBtn = ({ opened }) => {
  return <button className = { `btn btn-arrow ${opened ? 'btn-arrow--opened' : ''}` }></button>
}
const ControlBarBtn: React.FC<ControlbarBtnProps> = ({
  children,
  label,
  tooltipText,
  disabled,
  onClick,
  direction = 'top',
  no_bg,
  classes,
  isMobile,
  arrow,
  setOpenedMenu,
  menu_id,
  openedMenu,
  show_bottom_controlbar,
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  let ad_classnames;
  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setShowTooltip(false);
    setOpened(!opened);
    const opened_menu = !opened ? menu_id : undefined;
    setOpenedMenu(opened_menu);
  };
  const handleOnMouseMove = (e) => {
    if (opened) return;
    setShowTooltip(true);
  };
  const handleOnMouseLeave = (e) => {
    if (opened) return;
    setShowTooltip(false);
  };

  useEffect(() => {
    if (openedMenu && openedMenu !== menu_id) {
      setOpened(false);
    }
    if (!show_bottom_controlbar) {
      setOpened(false);
    }
  }, [openedMenu, show_bottom_controlbar]);

  if (classes && Array.isArray(classes)) {
    ad_classnames = classes.join(' ');
  }
  return (
    <div
    onMouseMove = { handleOnMouseMove }
    onMouseLeave = { handleOnMouseLeave }
    onClick = { handleOpen }
    className = {`cb-btn-container ${ad_classnames} ${direction === 'top' ? 'vjs-control' : ''} ${no_bg ? 'cb-btn-container--no-bg' : ''} ${disabled ? 'cb-btn-container--disabled' : ''}`}
    >
      <div className = { `cb-btn ${(no_bg && !disabled) ? '' : 'btn-effects'}` } >
        <div
        className = 'cb-btn-label'>
        {
          label && label.name &&
          !isMobile ?
          <span>
            {
              label.name
            }
          </span>
          :
          <></>
          
        }
        {
          arrow &&
          <div className = 'cb-btn-arrow'>
            <ArrowBtn
            opened = { opened }
            />
          </div>
        }
        {
          label && label.icon &&
          <div className = { `cb-btn-icon-container ${disabled ? '--btn-disabled' : ''}` }>
            <label.icon/> 
          </div>
        }
        {
          showTooltip &&
          <Tooltip
          direction = { direction }
          text = { tooltipText }
          />
        }
        </div>
        {
          opened && children &&
          <div className = { `cb-btn-content cb-btn-content--${direction}` }>
            {
              React.isValidElement(children) &&
              React.cloneElement(children, { ...props })
            }
          </div>
        }
        <BtnVeil
        onClick = { onClick }
        />
      </div>
    </div>
  )
}

export default ControlBarBtn;