import { memo } from 'react';
import Menu from './../menu-btn';
import { Menus } from '../controlbar';
import { Language } from '../../types';

const Icon = ({
  selected,
}: {
  selected: string
}) => <span className = { `icon icon-language` }>
      {
        selected.toUpperCase()
      }
  </span>

interface ILangMenu {
  language: Language
  setLanguage: any
  openedMenu: any
  setOpenedMenu: any
  menu_id: Menus
  show_bottom_controlbar: boolean
  isMobile?: boolean
  isSubMenu?: boolean
}

const LangMenu = ({
  language,
  setLanguage,
  openedMenu,
  setOpenedMenu,
  menu_id,
  show_bottom_controlbar,
  isMobile,
  isSubMenu
}: ILangMenu) => {
  const languages = ['en', 'ru'];
  const items = languages.map(item => ({
    id: item,
    value: item,
    label: item === 'ru' ? 'Russian' : 'English',
    checkbox: {
      checked: item === language
    }, 
    selected: item === language,
    cb: (e) => {
      const id = e.target.getAttribute('data-id');

      if (!id) {
        const _id = e.currentTarget.getAttribute('data-id');
        if (_id) {
          setLanguage(_id);
        }
        return;
      }
      setLanguage(id);
    }
  }));
  return (
    <Menu
    type = { 'simple' }
    selected = { language }
    label = { 'Language menu' }
    items = { items }
    language = { language }
    Icon = { () => <Icon selected = { language } /> }
    openedMenu = { openedMenu }
    setOpenedMenu = { setOpenedMenu }
    id = { menu_id }
    show_bottom_controlbar = { show_bottom_controlbar }
    isMobile = { isMobile }
    isSubMenu = { isSubMenu }
    />
  )
}

export default memo(LangMenu);